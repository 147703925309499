import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { useGameState } from "./GameStateContext";
import {
  AlphabetLetter,
  Attempt,
  AttemptLetterType,
  deepCopy,
  emptyAttempt,
  isAlphabetLetter,
  LimitedArray,
} from "./gameStateDataStructures";
import { HiddenInput, LetterInputDisplay } from "./LetterInput";
import { callRefs } from "./reactUtils";
import { LetterButtonDisplayComp } from "./LetterButtonDisplay";
import { AddAttemptButton } from "./Buttons";

const AttemptsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
`;

// const AttemptHiddenInput = styled.input`
const AttemptHiddenInput = styled(HiddenInput)`
  user-select: none;
  left: 10px;
`;

const highlightOffset = 5;
const AttemptContainer = styled.div<{ highlightPositions: [number, number] }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin: 0.5em;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: ${({ highlightPositions }) => highlightPositions[0]}px;
    transform: translateX(-${highlightOffset / 2}px);
    top: -${highlightOffset / 2}px;
    width: ${({ highlightPositions }) =>
      highlightPositions[1] - highlightPositions[0] + highlightOffset}px;
    height: calc(100% + ${highlightOffset}px);
    display: block;
    box-sizing: border-box;
    border-color: var(--highlight-color);
    border-style: ${({ highlightPositions }) =>
      highlightPositions[1] - highlightPositions[0] ? "solid" : "none"};
    border-width: 3px;
    pointer-events: none;
    ${({ highlightPositions }) =>
      highlightPositions[1] - highlightPositions[0] &&
      css`
        box-shadow: 0 0 0 1px var(--text-color);
      `}
  }
`;

const AttemptLetterWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 0.3em;

  &:first-child {
    margin-left: 0;
  }
`;

const AttemptLetterDisplay = styled(LetterInputDisplay)`
  cursor: text;
  user-select: none;
  font-size: 1.5em;
`;

const AttemptButton = styled(LetterButtonDisplayComp).attrs(() => ({
  type: "button",
}))`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 0.75em;
  font-weight: bold;
  margin-top: 0.25em;
`;

type AttemptInputProps = Omit<JSX.IntrinsicElements["input"], "onChange"> & {
  attempt: Attempt;
  onChange: (attempt: Attempt) => void;
};

function isRefCallback<T>(value: unknown): value is React.RefCallback<T> {
  return typeof value === "function";
}

function getElementRef<T>(ref: React.Ref<T>) {
  return isRefCallback(ref) ? ref : undefined;
}

type AttemptLetterProps = Omit<
  JSX.IntrinsicElements["div"] & JSX.IntrinsicElements["button"],
  "ref"
> & {
  isFocused: boolean;
  letter: AttemptLetterType | null;
  onResultClick: () => void;
  letterIndex: number;
};

const AttemptLetter = React.forwardRef<HTMLDivElement, AttemptLetterProps>(
  function AttemptLetterRef(
    { isFocused, children, letter, letterIndex, onResultClick, ...props },
    ref,
  ) {
    const resultProps = {
      isDiscovered: letter?.result === "discovered",
      isCorrect: letter?.result === "correct",
    };

    const buttonText = useMemo(() => {
      if (!letter?.value) return "";

      switch (letter?.result) {
        case "discovered":
          return "almost";
        case "correct":
          return "right";
        case "eliminated":
          return "wrong";
        default:
          return "";
      }
    }, [letter]);

    return (
      <AttemptLetterWrapper>
        <AttemptLetterDisplay
          forwardedAs="div"
          className={isFocused ? "focused" : ""}
          ref={ref}
          {...resultProps}
          {...props}
        >
          {letter?.value || ""}
        </AttemptLetterDisplay>
        <AttemptButton
          {...resultProps}
          onClick={onResultClick}
          data-prevent-focus-loss
        >
          {buttonText}
        </AttemptButton>
      </AttemptLetterWrapper>
    );
  },
);

const AttemptInput = React.forwardRef<HTMLInputElement, AttemptInputProps>(
  function ForwardedAttemptInput({ attempt, onChange, ...props }, ref) {
    const [attemptInput, setAttemptInput] = useState<HTMLInputElement>();
    const [lettersContainer, setLettersContainer] = useState<HTMLDivElement>();
    const [highlightStartEl, setHighlightStartEl] = useState<HTMLElement>();
    const [highlightEndEl, setHighlightEndEl] = useState<HTMLElement>();
    const [highlightPositions, setHighlightPositions] = useState<
      [number, number]
    >([0, 0]);
    const resetPositionTimeout = useRef<ReturnType<typeof setTimeout> | null>(
      null,
    );
    const [caretPosition, setCaretPosition] = useState<[number, number] | null>(
      null,
    );
    const hasHighlight = caretPosition?.[0] !== caretPosition?.[1];
    const resetPosition = useCallback(() => {
      if (!attemptInput) return;

      if (document.activeElement !== attemptInput) {
        setCaretPosition(null);
        setHighlightPositions([0, 0]);
        return;
      }

      setCaretPosition([
        attemptInput.selectionStart ?? 0,
        attemptInput.selectionEnd ?? 0,
      ]);
    }, [attemptInput]);

    useEffect(() => {
      setHighlightPositions([
        highlightStartEl?.offsetLeft ?? 0,
        (highlightEndEl?.offsetLeft ?? 0) + (highlightEndEl?.offsetWidth ?? 0),
      ]);
    }, [highlightStartEl, highlightEndEl]);

    useEffect(
      function monitorInput() {
        if (!attemptInput) return;

        const input = attemptInput;
        const onActivity = (ev: Event) => {
          if (
            document.activeElement === input &&
            resetPositionTimeout.current &&
            ev.type === "focus"
          ) {
            clearTimeout(resetPositionTimeout.current);
            resetPositionTimeout.current = null;
            return;
          }

          resetPositionTimeout.current = setTimeout(resetPosition, 15);
        };

        input.addEventListener("keydown", onActivity);
        input.addEventListener("keyup", onActivity);
        input.addEventListener("focus", onActivity);
        input.addEventListener("blur", onActivity);
        input.addEventListener("mouseup", onActivity);
        input.addEventListener("mousedown", onActivity);
        input.addEventListener("change", onActivity);
        input.addEventListener("select", onActivity);
        window.addEventListener("blur", onActivity, true);
        window.addEventListener("focus", onActivity, true);

        return () => {
          input.removeEventListener("keydown", onActivity);
          input.removeEventListener("keyup", onActivity);
          input.removeEventListener("focus", onActivity);
          input.removeEventListener("blur", onActivity);
          input.removeEventListener("mouseup", onActivity);
          input.removeEventListener("mousedown", onActivity);
          input.removeEventListener("change", onActivity);
          input.removeEventListener("select", onActivity);
          window.removeEventListener("blur", onActivity, true);
          window.removeEventListener("focus", onActivity, true);
        };
      },
      [attemptInput, resetPosition],
    );

    useEffect(
      function monitorKeys() {
        if (!attemptInput) return;

        const input = attemptInput;

        const onActivity = (evt: KeyboardEvent) => {
          if (document.activeElement !== input) return;

          const selectionStart = input.selectionStart || 0;
          const selectionEnd = input.selectionEnd || 0;
          const inputHasSelection = selectionStart !== selectionEnd;

          const target = evt.target as Maybe<HTMLInputElement>;
          if (!target) return;

          const isShiftDown = evt.shiftKey;
          const valueLength = input.value.length;
          const currentPosition = Math.min(target.selectionEnd ?? 0, 4);
          switch (evt.key) {
            case "ArrowLeft":
              if (isShiftDown || currentPosition > 0) {
                evt.stopPropagation();
                if (!inputHasSelection && currentPosition > 4) {
                  target.setSelectionRange(4, 4);
                }
                if (isShiftDown && target.selectionDirection === "none") {
                  evt.preventDefault();
                  target.setSelectionRange(
                    currentPosition,
                    currentPosition + 1,
                  );
                  target.selectionDirection = "backward";
                }
                return;
              }
              break;
            case "ArrowRight":
              if (isShiftDown || currentPosition < Math.min(valueLength, 4)) {
                evt.stopPropagation();
              }
              break;
            case "ArrowUp":
              if (isShiftDown || currentPosition > 0) {
                evt.stopPropagation();
              }
              break;
            case "ArrowDown":
              if (isShiftDown || currentPosition < valueLength - 1) {
                evt.stopPropagation();
              }
              break;
            case "Backspace":
              if (target.selectionStart !== target.selectionEnd) {
                break;
              }
              if (attempt[currentPosition]?.value) {
                evt.preventDefault();
                const newAttempt = [...attempt];
                newAttempt.splice(currentPosition, 1);
                newAttempt.fill(
                  { value: null, result: "eliminated" },
                  valueLength - 1,
                  5,
                );
                onChange(new LimitedArray(...newAttempt));
                setTimeout(
                  () =>
                    target.setSelectionRange(currentPosition, currentPosition),
                  0,
                );
              }
              break;
          }
        };

        attemptInput.addEventListener("keydown", onActivity);

        return () => {
          attemptInput.removeEventListener("keydown", onActivity);
        };
      },
      [attemptInput, onChange, attempt],
    );

    const updateAttemptFromWord = useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        const word = (evt.target as Maybe<HTMLInputElement>)?.value || "";
        const derivedAttempts = word
          .split("")
          .map<AttemptLetterType>((letter, ind) => {
            if (attempt[ind]?.value === letter.toUpperCase()) {
              return attempt[ind] as AttemptLetterType;
            }
            return {
              value: isAlphabetLetter(letter.toUpperCase())
                ? (letter.toUpperCase() as AlphabetLetter)
                : null,
              result: "eliminated",
            };
          });
        const wordAttempts = derivedAttempts.fill(
          { value: null, result: "eliminated" },
          word.length,
          5,
        );
        onChange(new LimitedArray(...wordAttempts));
      },
      [attempt, onChange],
    );

    const startRangeSelection = useCallback(
      (rangeStart: number) => {
        if (!lettersContainer || !attemptInput) return;

        const pressStart = new Date().getTime();
        const startRange = [rangeStart, rangeStart + 1];
        let currentRange = [...startRange] as [number, number];
        const breaks = [...lettersContainer.childNodes].map(
          (el: ChildNode) => (el as HTMLElement).getBoundingClientRect().left,
        );

        const trackSelection = (evt: MouseEvent) => {
          evt.preventDefault();
          if (new Date().getTime() - pressStart < 25) return;

          const mousePosition = evt.clientX;
          currentRange = breaks.reduce(
            (range, br, ind) =>
              mousePosition < br
                ? range
                : [
                    Math.min(startRange[0], ind),
                    Math.max(startRange[1], ind + 1),
                  ],
            currentRange,
          );
          attemptInput.focus();
          attemptInput.setSelectionRange(...currentRange);
          attemptInput.dispatchEvent(new Event("change"));
        };

        window.addEventListener("mousemove", trackSelection);
        window.addEventListener(
          "mouseup",
          () => {
            window.removeEventListener("mousemove", trackSelection);
          },
          { once: true },
        );
      },
      [lettersContainer, attemptInput],
    );

    const selectAll = useCallback(() => {
      if (!attemptInput) return;
      const range = [0, attemptInput.value.length] as [number, number];

      attemptInput.setSelectionRange(...range);
      setCaretPosition(range);
    }, [attemptInput]);

    const updateAttemptLetterResult = useCallback(
      (attemptLetter: AttemptLetterType | null, letterIndex: number) => {
        if (!attemptLetter || !attemptLetter?.value) return;

        const newAttempt = [...attempt];
        const result =
          attemptLetter.result === "eliminated"
            ? "discovered"
            : attemptLetter.result === "discovered"
              ? "correct"
              : "eliminated";
        newAttempt[letterIndex] = { ...attemptLetter, result };
        onChange(new LimitedArray(...newAttempt));
      },
      [attempt, onChange],
    );

    const attemptWord = attempt.map((letter) => letter?.value || "").join("");

    return (
      <div style={{ position: "relative" }}>
        <AttemptHiddenInput
          {...props}
          ref={(el) => {
            callRefs(el, getElementRef(setAttemptInput), ref);
          }}
          type="text"
          value={attemptWord}
          onChange={updateAttemptFromWord}
        />
        <AttemptContainer
          ref={getElementRef(setLettersContainer)}
          highlightPositions={highlightPositions}
        >
          {attempt.map((letter, ind) => {
            const isTargetPosition = caretPosition
              ? caretPosition[0] === ind ||
                (caretPosition[0] === 5 && ind === 4)
              : false;
            const isFocused = !hasHighlight && isTargetPosition;
            return (
              <div key={ind}>
                <AttemptLetter
                  letter={letter}
                  letterIndex={ind}
                  isFocused={isFocused}
                  onMouseDown={() => startRangeSelection(ind)}
                  onClick={() => {
                    attemptInput?.setSelectionRange(ind, ind);
                    attemptInput?.focus();
                  }}
                  onDoubleClick={selectAll}
                  onResultClick={() => updateAttemptLetterResult(letter, ind)}
                  ref={(el: HTMLElement | null) =>
                    hasHighlight &&
                    callRefs(
                      el,
                      caretPosition?.[0] === ind
                        ? getElementRef(setHighlightStartEl)
                        : undefined,
                      (caretPosition?.[1] ?? 0) - 1 === ind
                        ? getElementRef(setHighlightEndEl)
                        : undefined,
                    )
                  }
                />
              </div>
            );
          })}
        </AttemptContainer>
      </div>
    );
  },
);

const AttemptHeading = styled.h4`
  margin: 0.5em 0;
`;

const AttemptSection = styled.section`
  margin-top: 0.8em;
`;

export default function Attempts() {
  const {
    setAttempt,
    state: { attempts },
  } = useGameState();
  const [attemptRefs, setAttemptRefs] = useState<HTMLInputElement[]>([]);
  const [newAttemptAdded, setNewAttemptAdded] = useState(false);
  const attemptRef = useCallback<React.RefCallback<HTMLInputElement>>((ref) => {
    if (!ref) return;

    setAttemptRefs((refs) => {
      const existingRef = refs.find((r) => r.isSameNode(ref));
      if (existingRef) return refs;

      return [...refs, ref];
    });
  }, []);

  const addAttempt = useCallback<
    React.EventHandler<
      React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
    >
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      setAttempt(deepCopy(emptyAttempt), attempts?.length || 0);
      setNewAttemptAdded(true);
    },
    [setAttempt, attempts?.length],
  );

  useEffect(() => {
    if (!newAttemptAdded) return;

    attemptRefs[attemptRefs.length - 1]?.focus();
    setNewAttemptAdded(false);
  }, [attemptRefs, newAttemptAdded]);

  return (
    <AttemptsContainer>
      {attempts?.map((attempt, index) => (
        <AttemptSection key={index}>
          <AttemptHeading>Attempt {index + 1}</AttemptHeading>
          <form onSubmit={addAttempt}>
            <AttemptInput
              ref={attemptRef}
              attempt={attempt}
              onChange={(att) => setAttempt(att, index)}
            />
          </form>
        </AttemptSection>
      ))}
      <AddAttemptButton onClick={addAttempt} />
    </AttemptsContainer>
  );
}
