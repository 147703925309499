import React from "react";
import styled, { css, keyframes } from "styled-components";
import tinycolor from "tinycolor2";
import {
  CSSVariable,
  getAlphaColor,
  getCSSColor,
  StringColorInput,
} from "./colorUtils";

export interface LetterButtonDisplayLooseProps {
  isEliminated?: boolean;
  isDiscovered?: boolean;
  isCorrect?: boolean;
  isHinted?: boolean;
  inverted?: boolean;
}

type EliminatedBool<
  Target extends boolean,
  F extends boolean,
  S extends boolean
> = F extends true ? false : S extends true ? false : Target;

export type LetterButtonDisplayType<
  E extends boolean,
  D extends boolean,
  C extends boolean
> = Omit<JSX.IntrinsicElements["button"], "ref"> & {
  as?: keyof JSX.IntrinsicElements;
  isEliminated?: EliminatedBool<E, D, C>;
  isDiscovered?: EliminatedBool<D, E, C>;
  isCorrect?: EliminatedBool<C, E, D>;
  isHinted?: boolean;
  inverted?: boolean;
};

const getPulseFrames = (...colors: string[]) => {
  const breaks = 100 / (colors.length - 1);
  return keyframes`
    ${colors.map(
      (color, i) => `${breaks * i}% { background-color: ${color}; }`
    )}
`;
};

function letterPulseFrames(colorInput: StringColorInput | CSSVariable) {
  const color = getCSSColor(colorInput);
  const colorString = color.toRgbString();
  const isLight = color.isLight();
  const firstPhase = isLight
    ? tinycolor(colorString).lighten(10)
    : tinycolor(colorString).darken(10);
  const secondPhase = isLight
    ? tinycolor(colorString).darken(10)
    : tinycolor(colorString).lighten(10);
  return getPulseFrames(
    colorString,
    firstPhase.toRgbString(),
    colorString,
    secondPhase.toRgbString(),
    colorString
  );
}

const getAnimation = (color: StringColorInput | CSSVariable) => css`
  animation: ${letterPulseFrames(color)} linear 1.5s infinite;
`;

export const focusStyles = css<LetterButtonDisplayLooseProps>`
  ${({ inverted }) => css`
    outline: var(
        ${inverted ? "--opposite-highlight-color" : "--highlight-color"}
      )
      auto 2px;
    box-shadow: 0 0 0 0.1667em var(--highlight-color);
  `}
  ${({ isEliminated }) => isEliminated && getAnimation("#777")}
  ${({ isDiscovered }) =>
    isDiscovered && getAnimation("var(--discovered-color)")}
  ${({ isCorrect, isHinted }) =>
    (isCorrect || isHinted) && getAnimation("var(--correct-color)")}
`;

const eliminatedStyles = css`
  background-color: #777;
  color: rgb(175 175 175 / 65%);
  --input-text-color: rgb(175 175 175 / 65%);
`;

const discoveredStyles = css<LetterButtonDisplayLooseProps>`
  ${({ inverted }) => css`
    background-color: var(--discovered-color);
    color: var(--dark-background-color);
    border-color: var(--highlight-color);
    --input-text-color: var(--dark-background-color);

    ${!inverted
      ? ""
      : css`
          border-color: var(--opposite-highlight-color);
        `}
  `}
`;

const correctStyles = css<LetterButtonDisplayLooseProps>`
  ${({ inverted }) => css`
    background-color: var(--correct-color);
    border-color: var(--text-color);
    color: var(--dark-base-text-color);
    --input-text-color: var(--dark-base-text-color);
    ${!inverted
      ? ""
      : css`
          border-color: var(--opposite-text-color);
        `}
  `}
`;

const hintedStyles = css<LetterButtonDisplayLooseProps>`
  ${({ inverted }) => css`
    ${getAlphaColor("var(--dark-base-text-color)", 0.85)}
    border-color: var(--text-color);

    ${!inverted
      ? ""
      : css`
          border-color: var(--opposite-text-color);
        `}
  `}
`;

export const LetterButtonDisplayComp = styled.button<LetterButtonDisplayLooseProps>`
  box-sizing: border-box;
  border: 0.1667em solid var(--text-color);
  background-color: var(--background-color);
  border-radius: 0.416667em;

  color: var(--text-color);
  --input-text-color: var(--text-color);

  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2em;
  height: 2em;

  ${({ inverted }) =>
    !inverted
      ? ""
      : css`
          background-color: var(--opposite-background-color);
          border-color: var(--opposite-text-color);
          color: var(--opposite-text-color);
          --input-text-color: var(--opposite-text-color);
        `}

  &:focus,
  &:active,
  &.focused {
    ${focusStyles}
  }

  ${({ isEliminated }) => isEliminated && eliminatedStyles}
  ${({ isDiscovered }) => isDiscovered && discoveredStyles}
  ${({ isCorrect, isHinted }) => (isCorrect || isHinted) && correctStyles}
  ${({ isHinted }) => isHinted && hintedStyles}
`;

export type OnlyLetterButtonElementTypes = HTMLButtonElement | HTMLDivElement;
export type LetterButtonProps<
  E extends boolean,
  D extends boolean,
  C extends boolean
> = LetterButtonDisplayType<E, D, C> &
  StyledComponentProps<typeof LetterButtonDisplayComp>;

export type ExpandedProps = ExpandRecursively<
  LetterButtonProps<true, false, false>
>;

export const LetterButtonDisplay = React.forwardRef<
  OnlyLetterButtonElementTypes,
  LetterButtonDisplayType<boolean, boolean, boolean>
>(function LetterButtonDisplayInner(props, ref) {
  return <LetterButtonDisplayComp {...props} ref={ref} />;
});
