import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import styled, { createGlobalStyle } from "styled-components";
import { useFirstTimeCookie } from "./windowUtils";
import { useFocusableContext } from "./useFocusables";

try {
  if (typeof process === "undefined" || process.env?.NODE_ENV !== "test") {
    Modal.setAppElement("#root");
  }
} catch {
  // it's okay if this fails, usually
}

export const UnstyledButton = styled.button.attrs({
  type: "button",
})<object>`
  appearance: none;
  border: 0;
  display: block;
  box-shadow: none;
  outline: none;
  margin: 0;
`;

export const InfoButton = styled(UnstyledButton)`
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 1em;
  background-color: var(--background-color);
  border: 1px solid var(--text-color);
  color: var(--text-color);
  padding: 0.25em;
  font-weight: 600;

  position: fixed;
  bottom: 1em;
  right: 1em;

  &:hover,
  &:focus {
    border-color: var(--highlight-color);
    color: var(--highlight-color);
    outline: 2px solid var(--highlight-color);
  }

  @media (min-width: ${({ theme }) => theme.desktopSize}) {
    position: static;
  }
`;

const InfoModal = styled(Modal)`
  position: relative;
  width: 90vw;
  max-width: 50em;
  max-height: 60vh;
  border: 3px solid var(--text-color);
  background-color: var(--background-color);
  border-radius: 1em;
  padding: 1em;
  overflow: auto;

  h3 {
    margin-bottom: 0.25em;
  }

  p {
    margin-top: 0.25em;
  }

  @media (min-width: ${({ theme }) => theme.desktopSize}) {
    width: 75vw;
    max-height: 75vh;
  }
`;

const InfoOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(var(--background-color-rgb) / 75%);
  inset: 0px;
`;

const CloseButton = styled(UnstyledButton)`
  border: 2px solid var(--text-color);
  background-color: var(--background-color);
  color: var(--text-color);
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.15em 0.5em;
  border-radius: 0.3em;
  font-weight: 600;

  &:hover,
  &:focus {
    border-color: var(--highlight-color);
    color: var(--highlight-color);
    outline: 2px solid var(--highlight-color);
  }
`;

const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export default function Info({
  setTargetSection,
}: {
  setTargetSection: (section: number) => void;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { setFocusablesActive } = useFocusableContext();
  const [firstTimeCookie, setFirstTimeCookie] = useFirstTimeCookie(
    "firstTime",
    "true",
  );
  const ExtraStyles = useMemo(
    () => (modalIsOpen ? DisableBodyScroll : React.Fragment),
    [modalIsOpen],
  );
  const firstTimeValue = firstTimeCookie === "true";

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setFirstTimeCookie("false");
  }, [setFirstTimeCookie]);

  useEffect(() => {
    setModalIsOpen(firstTimeValue);
  }, [firstTimeValue]);

  return (
    <>
      <ExtraStyles />
      <InfoButton
        title="About Wordle Scratch"
        onClick={() => setModalIsOpen(true)}
      >
        ?
      </InfoButton>
      <InfoModal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={() => {
          setFocusablesActive(true);
          closeModal();
        }}
        onAfterOpen={() => setFocusablesActive(false)}
        overlayElement={({ style, ...props }, contentElement) => (
          <InfoOverlay {...props}>{contentElement}</InfoOverlay>
        )}
      >
        <h3>Want a quick overview?</h3>
        <CloseButton title="Close" onClick={closeModal}>
          X
        </CloseButton>
        <p>
          Check out this{" "}
          <a href="https://www.tiktok.com/t/ZT8rToKrK/" target="_blank">
            ~6 minute TikTok
          </a>{" "}
          (3 minutes at double speed!) by the <strong>Wordle Scratch</strong>{" "}
          creator about what it is and how to use it.
        </p>
        <h3>What is Wordle Scratch?</h3>
        <p>
          <strong>Wordle Scratch</strong> is a Wordle letter tracker. As you
          progress through the game, <strong>Wordle Scratch</strong> can help to
          prevent mistakes, and may even help you see some possibilities you
          hadn&apos;t considered.
        </p>
        <h3>How do I use it?</h3>
        <p>
          As you play Wordle, log your <strong>Attempts</strong> in{" "}
          <strong>Wordle Scratch</strong>, and mark each letter as Discovered,
          Correct, or Incorrect (&quot;wrong&quot;, &quot;almost&quot;,
          &quot;right&quot;). This will generate a list of all the possible
          configurations of the <strong>Discovered</strong> and{" "}
          <strong>Correct Letters</strong>.
        </p>
        <p>
          As you think of them, you can add <strong>Possibilities</strong>{" "}
          beneath these possible configurations. If you happen to make a mistake
          in an entered <strong>Possibility</strong>,{" "}
          <strong>Wordle Scratch</strong> will highlight it. As you add new{" "}
          <strong>Attempts</strong>, <strong>Wordle Scratch</strong> will reveal
          which of your previous entries are no longer valid.
        </p>
        <p>
          If you&apos;re using a keyboard, you can use the UP and DOWN keys to
          move between sections. You can use the LEFT and RIGHT keys to move
          between interactable elements.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <button
            type="button"
            style={{ fontSize: "1.5em" }}
            onClick={() => {
              setTargetSection(0);
              closeModal();
            }}
            title="See Game Details"
          >
            ⚙️
          </button>
        </div>
      </InfoModal>
    </>
  );
}
