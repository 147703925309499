import { GameStateShape } from "./GameStateContext";
import {
  AlphabetLetter,
  GameLetter,
  Guesses,
  LetterPosition,
  PositionState,
  positionHasState,
  hasState,
  isAlphabetLetter,
} from "./gameStateDataStructures";

function pipe<T, R>(arg: T, func: (arg: T) => R): R {
  return func(arg);
}

const getPositionIndexes = (
  positions: LetterPosition[],
  ...states: PositionState[]
) =>
  positions
    .map((pos, ind) => (positionHasState(pos, ...states) ? ind : undefined))
    .filter<number>((pos): pos is number => typeof pos === "number");

const modifyTemplate = (
  template: string,
  position: number,
  letter: AlphabetLetter
) => {
  return pipe(template.split(""), (arr) => {
    arr[position] = isAlphabetLetter(arr[position]) ? arr[position] : letter;
    return arr.join("");
  });
};

const getTemplates = (dl: GameLetter, prevTemplates: string[]) => {
  if (!(dl?.letter || dl?.instanceCount)) return [];

  const totalInstances = dl.instanceCount;
  const correctPositions = getPositionIndexes(
    dl.positions,
    "correct",
    "hinted"
  );
  const unknownPositions = getPositionIndexes(dl.positions, "unknown");

  let templates = correctPositions.length
    ? prevTemplates.flatMap((t) =>
        correctPositions.map((cp) => modifyTemplate(t, cp, dl.letter))
      )
    : [...prevTemplates];
  const loops = ((total: number) => {
    const ls = [];
    while (total--) ls.push(true);
    return ls;
  })(totalInstances);

  templates =
    loops.length && unknownPositions.length
      ? loops.reduce(
          (tmpls) =>
            dl.positions.flatMap((pos, ind) =>
              pos.state !== "incorrect"
                ? tmpls.map((t) => modifyTemplate(t, ind, dl.letter))
                : []
            ),
          templates
        )
      : templates;

  templates = templates.filter(
    (t) =>
      t.split("").filter((l) => l === dl.letter).length === dl.instanceCount
  );

  return [...new Set(templates)];
};

const scoreGuess = (guess: string, template: string) => {
  const letters = template.split("");
  const guessLetters = guess.split("");
  const score = letters.reduce((score, letter, i) => {
    if (letter === guessLetters[i]) return score + 20;
    if (guessLetters.includes(letter)) return score + 10;
    return score;
  }, 0);
  return score;
};

const matchGuess = (guess: string, templates: string[]) => {
  const match = templates.reduce(
    ({ score, currentTemplate }, template) => {
      const newScore = scoreGuess(guess, template);
      if (newScore > score)
        return { score: newScore, currentTemplate: template };
      return { score, currentTemplate };
    },
    { score: 0, currentTemplate: templates[0] }
  );
  return match.currentTemplate;
};

export const getGuesses = (newState: Partial<GameStateShape>) => {
  const { guesses = {}, gameLetters } = newState;
  const foundLetters = gameLetters?.filter((gl) => gl.instanceCount) || [];
  const correctLetters = foundLetters.filter((gl) =>
    hasState(gl, "correct", "hinted")
  );

  const baseTemplate = correctLetters.reduce((final, gl) => {
    const correctIndexes = gl.positions.flatMap((pos, ind) =>
      positionHasState(pos, "correct", "hinted") ? [ind] : []
    );
    const correctTemplate = correctIndexes.reduce(
      (template, ind) => modifyTemplate(template, ind, gl.letter),
      final
    );
    return correctTemplate;
  }, "00000");

  const allTemplates = foundLetters.length
    ? foundLetters.reduce(
        (tmpls, dl) => getTemplates(dl, tmpls),
        [baseTemplate]
      )
    : ["00000"];

  if (!allTemplates.length) allTemplates.push("00000");

  const finalGuesses = allTemplates.reduce(
    (guessObj, template) => ({ ...guessObj, [template]: [] }),
    {} as Guesses
  );
  if (Object.keys(finalGuesses).length === 0) finalGuesses["00000"] = [];

  const allGuesses = Object.values(guesses).flat();

  allGuesses.forEach((guess) => {
    finalGuesses[matchGuess(guess, allTemplates)]?.push(guess);
  });

  return finalGuesses;
};
