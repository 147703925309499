import React, { useCallback, useEffect, useState } from "react";
import styled, { createGlobalStyle, css } from "styled-components";
import AppSection from "./AppSection";
import BlockLetters from "./BlockLetters";
import { getCSSColor } from "./colorUtils";
import CorrectLettersSection from "./CorrectLettersSection";
import DiscoveredLettersSection from "./DiscoveredLettersSection";
import { GameStateProvider } from "./GameStateContext";
import Guesses from "./Guesses";
import LetterEliminationSection from "./LetterEliminationSection";
import {
  ResetButton,
  PageToggleButton,
  PageToggleTargetContainerSection,
  PageToggleTargetContainerSectionDisplay,
} from "./Buttons";
import useAutoScroll from "./useAutoScroll";
import useFocusables, { useFocusableContext } from "./useFocusables";
import Favicon from "./Favicon";
import { darkTheme, globalCssVars, lightTheme } from "./globalCss";
import { useColorScheme } from "./windowUtils";
import Info from "./Info";
import Attempts from "./Attempts";
import { useDisplayState } from "./theme";
import GameBaord from "./GameBoard";
import useFocusLossPrevention from "./useFocusLossPrevention";

const hStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:first-child {
      margin-top: 0;
    }
  }
`;

const srOnly = css`
  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
`;

const formReset = css`
  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  form,
  input {
    all: unset;
  }
`;

const GlobalCss = createGlobalStyle`
  html {
    max-height: 100vh;
    overflow: visible;
  }

  :root {
    ${globalCssVars}
  }

  ${hStyles}
  ${srOnly}
  ${formReset}

  div, section, main, button {
    box-sizing: border-box;
  }

  p {
    line-height: 1.6em;
  }

  a {
    color: var(--highlight-color);
    text-decoration: none;

    &:hover, &:active, &:focus {
      text-decoration: underline;
      outline: 1px solid var(--highlight-color);
    }
  }

  body {
    margin: 0;
    box-sizing: border-box;

    @media (prefers-color-scheme: light) {
      ${lightTheme}
    }

    &.light {
      ${lightTheme}
    }

    section {
      border: 1px solid transparent;

      &:focus-within {
        border: 1px solid var(--highlight-color);
      }
    }

    #root {
      min-height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      @media (min-width: ${({ theme }) => theme.desktopSize}) {
        align-items: center;
      }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .ReactModal__Overlay {
      z-index: 100;
    }
  }

  body, body.dark {
    ${darkTheme}
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: var(--base-font);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    input, button, div, p, span, a, h1, h2, h3, h4, h5, h6 {
      font-family: var(--base-font);

      &:disabled {
        cursor: default;
      }
    }

    &.ReactModal__Body--open {
      .ReactModalPortal {
        position: fixed;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
`;

const HeaderContainer = styled(SectionContainer)`
  border-bottom: 2px solid var(--highlight-color);
  background-color: var(--background-color);
  z-index: 101;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  line-height: 2em;
  padding: 1em;
  flex-grow: 0;
  max-width: var(--desktop-width);
  width: 100%;
  justify-content: space-between;

  a:first-child {
    text-decoration: none;
    color: var(--text-color);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
`;

const getContainerBoxShadow = () => {
  const cssColor = getCSSColor("var(--background-color)");
  const shadowColor = cssColor.darken(3).toRgbString();
  return `box-shadow: 0 0 16px 3px ${shadowColor};`;
};

const AppContainer = styled(SectionContainer)`
  flex-grow: 1;
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: var(--desktop-width);
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: stretch;
  z-index: 0;
  border-left: 2px solid rgb(var(--highlight-color-rgb) / 45%);
  border-right: 2px solid rgb(var(--highlight-color-rgb) / 45%);
  ${() => getContainerBoxShadow()}

  & > * > div {
    background-color: var(--background-color);
  }

  ${({ theme }) => theme.desktopQuery} {
    justify-content: center;
    overflow: auto;
    max-width: var(--desktop-width);
  }
`;

const MainContainer = styled(AppSection)`
  // nothing for now
`;

const sidebarVisibility = css<{ visibility: "hidden" | "visible" }>`
  visibility: ${({ visibility }) => visibility};
  ${({ visibility }) =>
    visibility === "hidden" &&
    css`
      display: none;
    `}}
`;

const Sidebar = styled(AppSection)<{
  open: boolean;
  visibility: "hidden" | "visible";
}>`
  max-width: 100%;
  padding-right: 0;
  background-color: var(--background-color);
  position: absolute;
  z-index: 1001;
  left: ${({ open }) => (open ? "0" : "-100vw")};
  transition: left 0.25s ease-in-out;

  ${sidebarVisibility}

  ${({ theme }) => theme.desktopQuery} {
    max-width: 360px;
    left: ${({ open }) => (open ? "0" : "-360px")};
  }
`;

const SidebarSection = styled.div`
  margin-bottom: 1em;
  padding: 0 1em;
`;

const EliminationSection = styled(SidebarSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 0.75em;
`;

const DiscoveredSection = styled(SidebarSection)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const InnerSectionHeader = styled.h3.attrs(() => ({
  tabIndex: -1,
}))`
  margin-bottom: 0;
`;

export const HelpText = styled.p`
  margin-top: 0;
  color: var(--hint-text);
  margin-bottom: 0.75em;
  font-size: 0.9em;
  line-height: 1em;
  text-transform: lowercase;

  ${PageToggleTargetContainerSectionDisplay} section & {
    margin-bottom: 0;
    margin-left: 0.6em;
    margin-right: 0.6em;
    flex-grow: 1;
    flex-basis: 30%;
  }
`;

const CorrectLettersContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--highlight-color);
  padding: 0.75em;

  & > div:first-child {
    margin-bottom: 0.25em;
    flex-basis: 100%;
  }
`;

const AttemptsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  border-bottom: 1px solid var(--highlight-color);
  padding: 0.75em;
`;

const PossibleWordsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1em;
  justify-content: flex-start;
  align-items: stretch;
`;

const MainResetButton = styled(ResetButton)`
  align-self: center;
  min-width: 300px;
  padding: 0;
`;

function App() {
  useFocusables();
  useFocusLossPrevention();
  const { focusablesActive } = useFocusableContext();
  const [appContainer, setAppContainer] = useState<HTMLDivElement | null>(null);
  const [headerContainer, setHeaderContainer] = useState<HTMLDivElement | null>(
    null,
  );
  const [mainContainer, setMainContainer] = useState<HTMLElement | null>(null);
  const [sidebarContainer, setSidebarContainer] = useState<HTMLElement | null>(
    null,
  );
  const { toggleTargetSection, targetSection, setTargetSection } =
    useAutoScroll(appContainer);
  const colorScheme = useColorScheme();
  const { isDesktop } = useDisplayState();
  const detailsOpen = targetSection === 0;

  const [sidebarVisibility, setSidebarVisibility] = useState<
    "visible" | "hidden"
  >("hidden");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const closeSidebar = useCallback(() => {
    if (!isDesktop || !detailsOpen) return;

    setTargetSection(1);
  }, [detailsOpen, isDesktop, setTargetSection]);

  useEffect(
    function closeDetailsOnMainClick() {
      if (!mainContainer) return;

      mainContainer.addEventListener("click", closeSidebar, {
        capture: true,
        passive: true,
      });

      return () => {
        mainContainer.removeEventListener("click", closeSidebar, {
          capture: true,
        });
      };
    },
    [closeSidebar, mainContainer],
  );

  useEffect(
    function toggleSidebar() {
      if (detailsOpen) {
        setSidebarVisibility("visible");
        setTimeout(() => {
          setSidebarOpen(true);
          sidebarContainer?.querySelector("h2")?.focus();
        }, 0);
      } else {
        if (sidebarContainer?.contains(document.activeElement))
          mainContainer?.querySelector("h3")?.focus();
        setSidebarOpen(false);
        setTimeout(() => setSidebarVisibility("hidden"), 300);
      }
    },
    [detailsOpen, sidebarContainer, mainContainer],
  );

  useEffect(
    function closeSidebarOnEsc() {
      const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === "Escape") closeSidebar();
      };

      window.addEventListener("keydown", handleKeydown, { capture: true });

      return () => {
        window.removeEventListener("keydown", handleKeydown, { capture: true });
      };
    },
    [closeSidebar],
  );

  useEffect(
    function scrollOnBlur() {
      if (!focusablesActive) return;

      const handleBlur = () => {
        setTimeout(() => {
          if (
            document.activeElement &&
            document.activeElement !== document.body
          )
            return;

          // document.scrollingElement?.scrollTo(0, 0);
        }, 0);
      };

      window.addEventListener("blur", handleBlur, { capture: true });

      return () => {
        window.removeEventListener("blur", handleBlur, { capture: true });
      };
    },
    [focusablesActive],
  );

  useEffect(
    function setHeaderPadding() {
      if (!headerContainer) return;
      const setTopPadding = () => {
        document.body.style.setProperty(
          "--header-height",
          `${headerContainer.offsetHeight}px`,
        );
      };

      const headerObserver = new ResizeObserver(setTopPadding);
      headerObserver.observe(headerContainer);
      window.addEventListener("resize", setTopPadding);

      setTopPadding();

      return () => {
        headerObserver.disconnect();
        window.removeEventListener("resize", setTopPadding);
      };
    },
    [headerContainer],
  );

  useEffect(
    function setBodyClass() {
      document.body.classList.add(colorScheme);

      return () => {
        document.body.classList.remove(colorScheme);
      };
    },
    [colorScheme],
  );

  useEffect(function resetWindowScroll() {
    const handleFocusOut = () => {
      setTimeout(() => {
        if (document.activeElement?.nodeName === "INPUT") return;

        // window.scrollTo(window.scrollX, 0);
      }, 25);
    };

    window.addEventListener("focusout", handleFocusOut, { capture: true });

    return () => {
      window.removeEventListener("focusout", handleFocusOut, { capture: true });
    };
  }, []);

  return (
    <GameStateProvider>
      <Favicon />
      <GlobalCss />
      <HeaderContainer ref={setHeaderContainer}>
        <Header>
          <a href="/" aria-label="Wordle Scratch" title="Wordle Scratch">
            <span style={{ display: "flex", flexFlow: "row wrap" }}>
              <BlockLetters>WORDLE</BlockLetters>
            </span>
            <span
              style={{
                fontFamily: "goodlife-script, sans-serif",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "1.7em",
                lineHeight: "1.5em",
              }}
            >
              &nbsp;Scratch
            </span>
          </a>
          <Info {...{ setTargetSection }} />
        </Header>
      </HeaderContainer>
      <AppContainer ref={setAppContainer}>
        <Sidebar
          ref={setSidebarContainer}
          data-page="true"
          open={sidebarOpen}
          visibility={sidebarVisibility}
        >
          <PageToggleTargetContainerSection left>
            <section>
              <PageToggleButton
                onClick={toggleTargetSection}
                aria-describedby="possibilities-instructions"
              >
                Back to Game
              </PageToggleButton>
              <HelpText id="possibilities-instructions">
                Press flag to go back.
              </HelpText>
            </section>
          </PageToggleTargetContainerSection>
          <h2
            tabIndex={-1}
            style={{
              marginTop: "0.75rem",
              marginLeft: "0.75rem",
              marginRight: "0.75rem",
              marginBottom: "0",
            }}
          >
            Game Details
          </h2>
          <CorrectLettersContainer>
            <div>
              <InnerSectionHeader>Found Letters</InnerSectionHeader>
              <HelpText>Enter letters in their correct positions.</HelpText>
            </div>
            <CorrectLettersSection />
          </CorrectLettersContainer>
          <EliminationSection>
            <InnerSectionHeader>Eliminated Letters</InnerSectionHeader>
            <HelpText>Press a letter to eliminate it.</HelpText>
            <LetterEliminationSection />
          </EliminationSection>
          <DiscoveredSection>
            <InnerSectionHeader>Discovered Letters</InnerSectionHeader>
            <HelpText>
              Enter a letter.
              <br />
              Press a number to eliminate the position.
            </HelpText>
            <DiscoveredLettersSection />
          </DiscoveredSection>
          <ResetButton />
        </Sidebar>
        <MainContainer
          forwardedAs="main"
          data-page="true"
          ref={setMainContainer}
        >
          <GameBaord />
          <AttemptsContainer>
            <InnerSectionHeader>Attempts</InnerSectionHeader>
            <HelpText style={{ maxWidth: "70%" }}>
              Enter attempts so far.
              <br />
              Mark each letter as discovered, correct, or incorrect.
            </HelpText>
            <Attempts />
          </AttemptsContainer>
          <PossibleWordsContainer>
            <Guesses />
          </PossibleWordsContainer>
          <MainResetButton />
          <section style={{ margin: "1em", textAlign: "center" }}>
            <p style={{ maxWidth: "300px", margin: "0 auto" }}>
              <strong>Wordle Scratch</strong> is a tool by{" "}
              <a
                href="https://stevematney.com"
                target="_blank"
                rel="noreferrer"
              >
                Steve Matney
              </a>
              . If you love it,{" "}
              <a
                href="https://ko-fi.com/stevematney"
                target="_blank"
                rel="noreferrer"
              >
                buy me a coffee
              </a>
              !
            </p>
          </section>
        </MainContainer>
      </AppContainer>
    </GameStateProvider>
  );
}

export default App;
