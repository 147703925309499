import { useEffect, useState } from "react";

const desktopBreakpointQuery = "(min-width: 780px)";

const theme = {
  desktopQuery: `@media ${desktopBreakpointQuery}`,
  desktopSize: "780px",
} as const;

export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const handleMatch = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener("change", handleMatch);

    return () => {
      mediaQuery.removeEventListener("change", handleMatch);
    };
  }, [query]);

  return matches;
}

export function useDisplayState() {
  const [displayState, setDisplayState] = useState<{
    isDesktop: boolean;
    isMobile: boolean;
  }>({
    isDesktop: false,
    isMobile: true,
  });

  const isDesktop = useMatchMedia(desktopBreakpointQuery);

  useEffect(() => {
    setDisplayState({
      isDesktop,
      isMobile: !isDesktop,
    });
  }, [isDesktop]);

  return displayState;
}

export default theme;
