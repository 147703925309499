import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { getCSSColor } from "./colorUtils";
import { useGameState } from "./GameStateContext";
import { getRandomChars, isAlphabetLetter } from "./gameStateDataStructures";
import {
  LetterButtonDisplay,
  LetterButtonDisplayComp,
} from "./LetterButtonDisplay";
import { getGuessErrors, LetterError, useGuessErrors } from "./guessUtils";
import { getNextFocusable } from "./useFocusables";
import { DestructiveButton } from "./Buttons";
import { HelpText } from "./App";

const GuessesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
`;

const GuessContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 290px;
  padding: 0.5em;
  margin-bottom: 1.75em;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0.5em;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: var(--background-color);
  z-index: 2;

  ${LetterButtonDisplayComp} {
    margin: 0.25em;
  }
`;

const ButtonSection = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  min-height: 2.35em;
`;

const baseButtonStyles = css`
  appearance: none;
  background-color: var(--dark-base-text-color);
  border: 2px solid var(--light-base-text-color);
  border-radius: 3px;
  pointer-events: all;
  margin-right: 0.5em;
  font-size: 1em;
  line-height: 1em;

  &:focus,
  &:hover {
    border-color: var(--highlight-color);
    border-width: 3px;
    margin-right: 0.425em;
    margin-left: -0.075em;
  }
`;

const SubmitGuessButton = styled.button.attrs({ type: "submit" })`
  ${baseButtonStyles}
  color: var(--background-color);
  padding: 0.05em 0.25em;
  font-weight: normal;
  display: none;
  color: var(--highlight-color);
`;

const RemoveGuessButton = styled.button.attrs({ type: "button" })`
  ${baseButtonStyles}

  width: 1.35em;
  height: 1.35em;

  background-position: center;
  background-size: 55%;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='%23a31818' height='800px' width='800px' version='1.1' id='Capa_1' viewBox='0 0 460.775 460.775' xml:space='preserve'%3E%3Cscript xmlns=''/%3E%3Cpath d='M285.08 230.397L456.218 59.27c6.076-6.077 6.076-15.911 0-21.986L423.511 4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127 0-8.08 1.639-10.993 4.55l-171.138 171.14L59.25 4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126 0-8.08 1.639-10.992 4.55L4.558 37.284c-6.077 6.075-6.077 15.909 0 21.986l171.138 171.128L4.575 401.505 c-6.074 6.077-6.074 15.911 0 21.986l32.709 32.719c2.911 2.911 6.865 4.55 10.992 4.55c4.127 0 8.08-1.639 10.994-4.55 l171.117-171.12l171.118 171.12c2.913 2.911 6.866 4.55 10.993 4.55c4.128 0 8.081-1.639 10.992-4.55l32.709-32.719 c6.074-6.075 6.074-15.909 0-21.986L285.08 230.397z'/%3E%3C/svg%3E");

  &:hover,
  &:focus {
    width: 1.5em;
    height: 1.5em;
  }
`;

const guessInputBackgroundColor = () => {
  const color = getCSSColor("var(--opposite-background-color)");
  color.setAlpha(0.1);
  return `background-color: ${color.toRgbString()};`;
};

const GuessInputForm = styled.form`
  display: block;
  position: relative;
  margin-bottom: 1em;

  & > * {
    width: 100%;
  }

  &:focus-within {
    ${SubmitGuessButton} {
      display: block;
    }
  }
`;

const GuessInputDisplay = styled.input.attrs({ type: "text" })`
  display: block;
  position: relative;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0);
  font-weight: 600;
  ${() => guessInputBackgroundColor()}

  border-radius: 3px;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  border 1px solid transparent;
  border-bottom-color: var(--text-color);
  line-height: 2em;
  caret-color: var(--text-color);
  padding: 0.25em 0.5em;

  &:focus, &:active {
    outline: none;
    border-bottom-color: var(--highlight-color);
  }

  &.error {
    border-bottom-color: var(--danger-color);
  }
`;

const ErrorDisplay = styled.div`
  box-sizing: border-box;
  border: 1px solid transparent;
  border-bottom-color: var(--danger-color);
  font-size: 0.75em;
  padding: 0.25em 0.5em;
  color: var(--danger-text-color);
`;

const GuessValueDisplay = styled(GuessInputDisplay)`
  color: var(--text-color);
  background-color: transparent;
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;

  span {
    &.error {
      color: var(--danger-text-color);
      text-decoration: line-through;
    }
  }

  &:focus-within {
    ${SubmitGuessButton} {
      display: block;
    }
  }
`;

const AddGuessButton = styled(LetterButtonDisplay)`
  font-size: 0.9em;
  align-self: center;
  height: 2em;
  width: auto;
  padding-left: 1em;
  padding-right: 1em;
  border-width: 0.1em;
`;

const GuessValue = ({
  value,
  onRemove = () => ({}),
  errors = [],
}: {
  value: Maybe<string>;
  onRemove?: () => void;
  errors?: LetterError[];
}) => {
  return (
    <GuessValueDisplay as="div">
      {value?.split("").map((letter, ind) => {
        const error = errors.some(
          (e) => e.position === ind || e.position === -1
        )
          ? "error"
          : "";
        return (
          <span key={`${letter}-${ind}`} className={error}>
            {letter}
          </span>
        );
      })}
      <ButtonSection>
        {value ? (
          <SubmitGuessButton title="Save Possibility">✓</SubmitGuessButton>
        ) : (
          <></>
        )}
        {errors.length ? (
          <RemoveGuessButton
            title="Clear Possibility"
            onClick={(e) => {
              e.preventDefault();
              onRemove();
            }}
          />
        ) : (
          <></>
        )}
      </ButtonSection>
    </GuessValueDisplay>
  );
};

type InputProps = Omit<
  JSX.IntrinsicElements["input"],
  "ref" | "type" | "onSubmit"
> &
  Pick<JSX.IntrinsicElements["form"], "onSubmit"> & {
    onRemove?: () => void;
  };

const noop = () => {
  // noop
};

const GuessInput = React.forwardRef<HTMLInputElement, InputProps>(
  function InnerInput(
    { onSubmit, value: propValue, onRemove, onChange: onChangeProp, ...props },
    ref
  ) {
    const [uniqueId] = useState(getRandomChars());
    const value = (propValue as Maybe<string>) || "";
    const errors = useGuessErrors(value);

    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (onChangeProp) onChangeProp(e);
      },
      [onChangeProp]
    );

    const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        let action: Maybe<() => unknown> = null;
        switch (e.key) {
          case "Backspace":
            action = !value ? onRemove : action;
            break;
          case "Enter":
            if (!value) {
              action = () => getNextFocusable()?.focus();
            } else {
              return;
            }
            break;
          case "ArrowUp":
          case "ArrowLeft":
            action =
              (e.target as HTMLInputElement).selectionStart === 0
                ? noop
                : action;
            break;
          case "ArrowDown":
          case "ArrowRight":
            action =
              (e.target as HTMLInputElement).selectionStart === value.length
                ? noop
                : action;
        }

        if (action) {
          e.preventDefault();
          action();
          return;
        }

        if (value) {
          e.stopPropagation();
          return;
        }
      },
      [onRemove, value]
    );

    const errorClass = errors.length ? "error" : "";
    const ariaInputProps = errors.length
      ? {
          "aria-describedby": `error-${uniqueId}`,
        }
      : {};

    return (
      <section style={{ border: 0 }}>
        <GuessInputForm
          onSubmit={(e) => {
            e.preventDefault();
            if (onSubmit) onSubmit(e);
          }}
        >
          <GuessInputDisplay
            ref={ref}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={value || ""}
            {...ariaInputProps}
            {...props}
            className={`${props.className || ""} ${errorClass}`.trim()}
          />
          <GuessValue
            errors={errors}
            value={value as string}
            onRemove={onRemove}
          />
          {errors.length ? (
            <ErrorDisplay id={`error-${uniqueId}`}>
              {errors[0].message}
            </ErrorDisplay>
          ) : (
            <></>
          )}
        </GuessInputForm>
      </section>
    );
  }
);

const GuessTemplate = ({ template }: { template: string }) => {
  const templateLetters = template.split("");
  return (
    <>
      {templateLetters.map((letter, ind) => (
        <LetterButtonDisplay as="span" key={`${letter || ""}-${ind}`}>
          {isAlphabetLetter(letter) ? letter : ""}
        </LetterButtonDisplay>
      ))}
    </>
  );
};

function Guess({ template, guesses }: { template: string; guesses: string[] }) {
  const { addGuess, updateGuess, removeGuess } = useGameState();
  const [newValue, setNewValue] = useState<string>("");
  const [focusNew, setFocusNew] = useState(false);
  const [focusIndex, setFocusIndex] = useState<number | null>(null);
  const runGuessUpdate = useCallback((guessAction: () => void) => {
    guessAction();
    setFocusNew(true);
  }, []);

  const onNewValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewValue(e.target.value.toUpperCase());
    },
    []
  );

  const templateLetters = template
    .split("")
    .map((letter) => (letter !== "0" ? letter.toUpperCase() : "blank"))
    .join(" ");
  const templateDescription = `Possible word template: ${templateLetters}`;

  return (
    <GuessContainer>
      <TemplateContainer
        onClick={() => setFocusNew(true)}
        aria-label={templateDescription}
        title={templateDescription}
      >
        <GuessTemplate template={template} />
      </TemplateContainer>
      <GuessInput
        ref={(el) => {
          if (focusNew && el) {
            el.focus();
            setFocusNew(false);
          }
        }}
        aria-label={`Guess 1 for "${templateLetters}"`}
        title={`Guess 1 for "${templateLetters}"`}
        value={newValue}
        onChange={onNewValueChange}
        onRemove={() => runGuessUpdate(() => setNewValue(""))}
        onSubmit={() =>
          runGuessUpdate(() => {
            addGuess({ template, guess: newValue });
            setNewValue("");
          })
        }
      />
      {guesses.map((guess, i) => (
        <GuessInput
          key={i}
          aria-label={`Guess ${i + 2} for "${templateLetters}"`}
          title={`Guess ${i + 2} for "${templateLetters}"`}
          value={guess}
          ref={(el) => {
            if (focusIndex !== i || !el) return;

            el.focus();
            setFocusIndex(null);
          }}
          onSubmit={() => {
            const newFocusIndex = i + 1;
            if (newFocusIndex === guesses.length) {
              addGuess({ template, guess: "", final: true });
            }
            setFocusIndex(newFocusIndex);
          }}
          onChange={(e) =>
            updateGuess({ template, guess: e.target.value, index: i })
          }
          onRemove={() => {
            const removeAction = () => removeGuess({ template, index: i });
            if (i === 0) {
              runGuessUpdate(removeAction);
            } else {
              removeAction();
              setFocusIndex(i - 1);
            }
          }}
        />
      ))}
      <AddGuessButton
        onClick={() => {
          addGuess({ template, guess: "", final: true });
          setFocusIndex(guesses.length);
        }}
      >
        <span aria-hidden>+</span>&nbsp;Add Possibility
      </AddGuessButton>
    </GuessContainer>
  );
}

const HeaderTextContainer = styled.div`
  flex-basis: 50%;

  @media (min-width: ${({ theme }) => theme.desktopSize}) {
    flex-basis: 70%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;

  h3 {
    flex-basis: 100%;
    margin-bottom: 0;
  }
`;

const ClearButton = styled(DestructiveButton)`
  white-space: nowrap;
`;

export default function Guesses() {
  const { state, removeIncorrect } = useGameState();
  const { guesses = {} } = state;

  const errors = Object.values(guesses)
    .flat()
    .filter((guess) => getGuessErrors(state, guess).length);

  return (
    <>
      <HeaderContainer>
        <HeaderTextContainer>
          <h3>Possible Words</h3>
          <HelpText>
            Enter possibilities under their matching patterns.
          </HelpText>
        </HeaderTextContainer>
        {errors.length ? (
          <ClearButton onClick={removeIncorrect}>Clear Incorrect</ClearButton>
        ) : (
          <></>
        )}
      </HeaderContainer>
      <GuessesContainer>
        {Object.entries(guesses).map(([template, guesses]) => (
          <Guess key={template} template={template} guesses={guesses} />
        ))}
      </GuessesContainer>
    </>
  );
}
