export const objectHasMatch = <T = unknown>(
  orig: T,
  matcher: Maybe<Partial<T>>
) => {
  if (orig === matcher) return true;
  if (typeof orig !== "object" || typeof matcher !== "object")
    return orig === matcher;
  if (orig === null || matcher === null) return orig === matcher;

  if (Array.isArray(orig)) {
    if (!Array.isArray(matcher)) return false;
    return matcher.every((item, i) => orig[i] === item);
  }

  const origObj = orig as Record<string, unknown>;
  const matchObj = matcher as Record<string, unknown>;
  const matchKeys = Object.keys(matchObj);

  return matchKeys.every((key) => origObj[key] === matchObj[key]);
};

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export class LimitedArray<T> extends Array<T | null> {
  constructor(...args: (T | null)[]) {
    const [one = null, two = null, three = null, four = null, five = null] =
      args;
    super(one, two, three, four, five);
  }

  get validValues(): T[] {
    return [...this].filter<T>((b): b is T => b !== undefined && b !== null);
  }

  hasMatch(this: LimitedArray<T>, value: Maybe<Partial<T>>) {
    return (
      this.includes(value as T) ||
      this.some((item) => objectHasMatch(item, value))
    );
  }

  getMatch(this: LimitedArray<T>, value: Partial<T>) {
    return this.find((item) => objectHasMatch(item, value));
  }

  toJSON(this: LimitedArray<T>) {
    return [...this];
  }

  static get [Symbol.species]() {
    return Array;
  }
}

export const alphabetLetters = [
  "Q",
  "W",
  "E",
  "R",
  "T",
  "Y",
  "U",
  "I",
  "O",
  "P",
  "A",
  "S",
  "D",
  "F",
  "G",
  "H",
  "J",
  "K",
  "L",
  "Z",
  "X",
  "C",
  "V",
  "B",
  "N",
  "M",
] as const;

export const getRandomChars = () =>
  [0, 0, 0, 0, 0]
    .map(
      () => alphabetLetters[Math.floor(Math.random() * alphabetLetters.length)]
    )
    .join("");

export type AlphabetLetter = typeof alphabetLetters[number];

export const isAlphabetLetter = (
  value: Maybe<string>
): value is AlphabetLetter =>
  Boolean(value) && alphabetLetters.includes(value as AlphabetLetter);

export const isHintedPosition = (
  maybePosition: Maybe<number>
): maybePosition is number => typeof maybePosition === "number";

export type Guesses = {
  [template: string]: string[];
};

export const AttemptResults = ["eliminated", "discovered", "correct"] as const;
export type AttemptResult = typeof AttemptResults[number];

export type AttemptLetterType = {
  value: Maybe<AlphabetLetter>;
  result: AttemptResult;
};

export type Attempt = LimitedArray<AttemptLetterType>;
export const emptyAttempt = new LimitedArray(
  { value: null, result: "eliminated" },
  { value: null, result: "eliminated" },
  { value: null, result: "eliminated" },
  { value: null, result: "eliminated" },
  { value: null, result: "eliminated" }
) as Attempt;

export type PositionState = "unknown" | "correct" | "incorrect" | "hinted";

export type LetterPosition = {
  state: PositionState;
};

export type LetterPositions = [
  LetterPosition,
  LetterPosition,
  LetterPosition,
  LetterPosition,
  LetterPosition
];

export const hasState = (gameLetter: GameLetter, ...states: PositionState[]) =>
  hasPositionsState(gameLetter.positions, ...states);

export const hasPositionsState = (
  positions: LetterPositions | undefined,
  ...states: PositionState[]
) => positions?.some((p) => positionHasState(p, ...states));

export const positionHasState = (
  position: LetterPosition,
  ...states: PositionState[]
) => states.includes(position.state);

export type GameLetter = {
  letter: AlphabetLetter;
  eliminated: boolean;
  instanceCount: number;
  positions: LetterPositions;
  guessOrders: number[];
};

export const getLetterPositions = (): LetterPositions =>
  [...Array<LetterPosition>(5)].fill({
    state: "unknown",
  }) as LetterPositions;

export const gameLetters: GameLetter[] = alphabetLetters.map((letter) => ({
  letter,
  eliminated: false,
  instanceCount: 0,
  positions: getLetterPositions(),
  guessOrders: [],
}));

export const addGuessOrder = (gl: GameLetter, guessOrder?: number) =>
  guessOrder !== undefined
    ? {
        ...gl,
        guessOrders: gl.guessOrders
          .reduce(
            (newOrders, order) => [
              ...newOrders,
              newOrders.includes(order) ? order + 1 : order,
            ],
            [guessOrder]
          )
          .sort((a, b) => a - b),
      }
    : gl;

export const getLetter = (letter: AlphabetLetter, gameLetters: GameLetter[]) =>
  gameLetters.find((l) => l.letter === letter);
