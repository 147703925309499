import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { LetterButtonDisplay } from "./LetterButtonDisplay";

const getRandomStyle = (extras: string[] = []) => {
  const styles = [
    "default",
    "isEliminated",
    "isDiscovered",
    "isCorrect",
    ...extras,
  ];
  const style = styles[Math.floor(Math.random() * styles.length)];
  return style;
};

const BlockLetter = styled(LetterButtonDisplay)`
  font-weight: var(--block-letter-font-weight);
  width: 2.3em;
  height: 2.3em;
  margin: 0.15em;
  ${({ isEliminated }) =>
    isEliminated &&
    css`
      color: var(--dark-base-text-color);
    `}
`;

const getStyleProp = (targetStyle: string) =>
  targetStyle === "default" ? {} : { [targetStyle]: true };

const BlockLetters: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [style] = useState(getRandomStyle(["randomized"]));
  const childLetters = children as string;
  const getChildLetterStyles = useCallback(
    () => (style === "randomized" ? getRandomStyle() : style),
    [style]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const childLetterStyles = useCallback(
    (() => {
      const styles = childLetters
        .split("")
        .map(() => getStyleProp(getChildLetterStyles()));
      return () => styles;
    })(),
    [childLetters, getChildLetterStyles]
  )();

  return (
    <>
      {React.Children.map<React.ReactNode, React.ReactNode>(
        children,
        (child) => {
          if (typeof child === "string" || child instanceof String) {
            return child.split("").map((s, i) => {
              const styleProp = childLetterStyles[i];

              return (
                <BlockLetter key={s} forwardedAs="span" {...styleProp}>
                  {s}
                </BlockLetter>
              );
            });
          } else {
            return child;
          }
        }
      )}
    </>
  );
};
export default BlockLetters;
