import { useEffect, useState } from "react";
import { GameStateShape, useGameState } from "./GameStateContext";
import {
  AlphabetLetter,
  GameLetter,
  Guesses,
  isAlphabetLetter,
} from "./gameStateDataStructures";
import { getDiscoveredLetters } from "./game-state/discoveredLetters";
import { getCorrectLetters } from "./game-state/correctLetters";

export type LetterError = {
  message: string;
  letter: string;
  position: number;
};

const getIncorrectPosition = (
  discoveredLetters: GameLetter[] | undefined,
  letter: string,
  index: number
) => {
  const targetDiscovered = discoveredLetters?.find(
    ({ letter: discoveredLetter }) => discoveredLetter === letter
  );
  if (
    targetDiscovered &&
    targetDiscovered.positions[index]?.state === "incorrect"
  ) {
    const positionDisplay = index + 1;
    return {
      message: `"${letter}" is in position ${positionDisplay}, which has been eliminated for that letter.`,
      letter,
      position: index,
    };
  }
};

const getIncorrectLetter = (
  correctLetters: GameLetter[],
  letter: string,
  index: number
) => {
  const foundCorrectLetter = correctLetters.find((l) =>
    ["correct", "hinted"].includes(l.positions[index]?.state)
  );

  if (!foundCorrectLetter) return;
  if (foundCorrectLetter.letter === letter) return;

  const positionDisplay = index + 1;

  return {
    message: `"${letter}" is in position ${positionDisplay}; it should be "${foundCorrectLetter.letter}".`,
    letter,
    position: index,
  };
};

const getEliminatedLetter = (
  eliminatedLetters: AlphabetLetter[],
  letter: string,
  index: number
) => {
  const isEliminated =
    isAlphabetLetter(letter) && eliminatedLetters.includes(letter);
  if (isEliminated) {
    return {
      message: `"${letter}" has already been eliminated.`,
      letter,
      position: index,
    };
  }
};

const getOutOfBoundsLetter = (letter: string, index: number) => {
  if (index > 4) {
    return {
      message: "Word is too long.",
      letter,
      position: index,
    };
  }
};

const getMissingLetters = (
  knownLetters: GameLetter[],
  guess: Maybe<string>
) => {
  if ((guess?.length || 0) < 5) return;

  const hasAllKnown = knownLetters.every((kl) => guess?.includes(kl.letter));
  if (hasAllKnown) return;

  const missingLetters = knownLetters
    .filter((l) => !guess?.includes(l.letter))
    .map((l) => l.letter);
  const hasCommas = missingLetters.length > 2;
  const oxfordCommaList = hasCommas
    ? missingLetters.slice(0, -1).join(`", "`)
    : missingLetters.slice(0, -1).join(" ");
  const commaState = oxfordCommaList ? `"${oxfordCommaList}", ` : "";
  const finalSegment = `${hasCommas ? "and " : ""}"${missingLetters.slice(
    -1
  )}"`;
  return {
    message: `Word is missing ${commaState}${finalSegment}.`,
    letter: "",
    position: -1,
  };
};

export const getGuessErrors = (
  state: Partial<GameStateShape>,
  guess: Maybe<string>
) => {
  const { gameLetters = [] } = state;
  const correctLetters = getCorrectLetters(gameLetters, true);
  const discoveredLetters = getDiscoveredLetters(gameLetters);
  const knownLetters = gameLetters.filter((gl) => gl.instanceCount);
  const eliminatedLetters = gameLetters
    .filter((gl) => gl.eliminated)
    .map((gl) => gl.letter);
  const letters = guess?.split("") || [];
  const finalErrors = letters.reduce((newErrors, letter, index) => {
    return [
      ...newErrors,
      ...[
        getIncorrectLetter(correctLetters, letter, index) ||
          getIncorrectPosition(discoveredLetters, letter, index) ||
          getEliminatedLetter(eliminatedLetters, letter, index) ||
          getOutOfBoundsLetter(letter, index),
      ].filter<LetterError>((e): e is LetterError => Boolean(e)),
    ];
  }, [] as LetterError[]);
  const missingLetters = getMissingLetters(knownLetters, guess);
  return [...finalErrors, ...(missingLetters ? [missingLetters] : [])];
};

export const useGuessErrors = (guess: Maybe<string>) => {
  const { state } = useGameState();
  const [errors, setErrors] = useState<LetterError[]>([]);

  useEffect(() => {
    const letterErrors = getGuessErrors(state, guess);
    setErrors(letterErrors);
  }, [guess, state]);

  return errors;
};

const getEligibleGuesses = (
  state: Partial<GameStateShape>,
  gs: Guesses,
  [template, guesses]: [string, string[]]
) => ({
  ...gs,
  [template]: guesses.filter(
    (guess) => getGuessErrors(state, guess).length === 0
  ),
});

export const removeIncorrectGuesses = (state: Partial<GameStateShape>) => {
  const stateHasErrors = (st: Partial<GameStateShape>) =>
    Object.entries(st.guesses || {}).some(([_, guesses]) =>
      guesses.some((guess) => getGuessErrors(st, guess).length > 0)
    );

  let guesses = state.guesses;
  let hasErrors = stateHasErrors(state);
  while (hasErrors) {
    const { guesses: existingGuesses = {} } = state;
    guesses = Object.entries(existingGuesses).reduce<Guesses>(
      (gs, [template, guesses]) =>
        getEligibleGuesses(state, gs, [template, guesses]),
      existingGuesses
    );

    hasErrors = stateHasErrors({
      ...state,
      guesses,
    });
  }

  return guesses;
};
