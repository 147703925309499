import { useCallback, useEffect, useState } from "react";

const getDocumentWidth = () => document.documentElement.clientWidth;

const getPages = (container: HTMLElement) =>
  [...container.children].filter((c) => c.hasAttribute("data-page"));

export default function useAutoScroll(appContainer: HTMLElement | null) {
  const [targetSection, setTargetSection] = useState<number>(1);
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [shouldTrackScroll, setShouldTrackScroll] = useState<boolean>(true);

  const toggleTargetSection = useCallback(() => {
    setTargetSection((oldSection) => (oldSection ? 0 : 1));
    setTimeout(() => setScrolling(true), 0);
  }, []);

  const onFinishScroll = useCallback(
    (scrollComplete: () => void) => {
      if (!appContainer)
        return {
          listener: () => {
            // noop
          },
          remove: () => {
            // noop
          },
        };

      const testComplete = () => {
        const isComplete =
          appContainer.scrollLeft === getDocumentWidth() * targetSection;
        if (isComplete) {
          appContainer.removeEventListener("scroll", testComplete);
          scrollComplete();
        }
      };

      appContainer.addEventListener("scroll", testComplete);
      testComplete();
      return {
        listener: testComplete,
        remove: () => appContainer.removeEventListener("scroll", testComplete),
      };
    },
    [appContainer, targetSection]
  );

  useEffect(
    function trackScroll() {
      if (!appContainer || !shouldTrackScroll) return;

      let scrollTimeout = 0;
      const scrollEvent = () => {
        setScrolling(true);
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(() => {
          setTargetSection((oldSection) => {
            const windowWidth = getDocumentWidth();
            const appChildren = getPages(appContainer);
            const swipeBreak = windowWidth / 5;
            const leftSwitch = windowWidth * oldSection - swipeBreak;
            const rightSwitch = windowWidth * oldSection + swipeBreak;

            if (appContainer.scrollLeft < leftSwitch) {
              return Math.max(oldSection - 1, 0);
            } else if (appContainer.scrollLeft > rightSwitch) {
              return Math.min(oldSection + 1, appChildren.length - 1);
            }
            return oldSection;
          });

          setScrolling(false);
        }, 100) as unknown as number;
      };

      appContainer.addEventListener("scroll", scrollEvent);

      return () => {
        appContainer.removeEventListener("scroll", scrollEvent);
      };
    },
    [appContainer, targetSection, shouldTrackScroll]
  );

  useEffect(
    function trackResize() {
      if (!appContainer) return;

      const setShouldScroll = () => {
        const isDesktopView =
          getPages(appContainer)[targetSection].getBoundingClientRect().width <
          getDocumentWidth();

        if (isDesktopView) {
          getPages(appContainer).forEach((child) => {
            (child as HTMLElement).style.visibility = "visible";
          });
        }

        setShouldTrackScroll(!isDesktopView);
      };

      setShouldScroll();
      window.addEventListener("resize", setShouldScroll);

      return () => {
        window.removeEventListener("resize", setShouldScroll);
      };
    },
    [appContainer, targetSection]
  );

  useEffect(
    function goToTargetSection() {
      if (!appContainer) return;

      const documentWidth = getDocumentWidth();

      const shouldScroll =
        getPages(appContainer)[targetSection].getBoundingClientRect().width >=
        documentWidth;

      if (!shouldScroll) return;

      const finishScrolling = () => {
        setShouldTrackScroll(true);
      };

      const { remove: removeListener } = onFinishScroll(finishScrolling);

      setTimeout(() => {
        if (
          appContainer.scrollLeft === getDocumentWidth() * targetSection ||
          scrolling
        ) {
          finishScrolling();
          return;
        }
        setShouldTrackScroll(false);

        appContainer.scrollTo({
          left: getDocumentWidth() * targetSection,
          top: 0,
          behavior: "smooth",
        });
      }, 0);

      return removeListener;
    },
    [targetSection, scrolling, appContainer, onFinishScroll]
  );

  useEffect(
    function toggleVisibility() {
      if (!appContainer) return;
      if (scrolling) {
        getPages(appContainer).forEach(
          (child) => ((child as HTMLElement).style.visibility = "visible")
        );
        return;
      }

      const { remove } = onFinishScroll(() => {
        getPages(appContainer).forEach((child, i) => {
          const visibility = i === targetSection ? "visible" : "hidden";
          (child as HTMLElement).style.visibility = visibility;
        });
      });

      return remove;
    },
    [appContainer, targetSection, scrolling, onFinishScroll]
  );

  return { toggleTargetSection, setTargetSection, targetSection };
}
