import type { GameStateShape } from "../GameStateContext";
import {
  Attempt,
  AttemptLetterType,
  AttemptResult,
  GameLetter,
  LetterPositions,
  getLetterPositions,
  objectHasMatch,
} from "../gameStateDataStructures";

const isFound = (att: AttemptLetterType | null) =>
  (["correct", "discovered"] as AttemptResult[]).includes(
    att?.result || "eliminated"
  );

const isAttemptFoundMatch = (gl: GameLetter, al?: AttemptLetterType | null) =>
  al?.value === gl.letter && isFound(al);

const resetGameLetter = (gl: GameLetter): GameLetter => ({
  ...gl,
  positions: getLetterPositions(),
  eliminated: false,
  guessOrders: [],
  instanceCount: 0,
});

export function setAttempt(
  attempt: Attempt,
  index: number,
  attempts: Attempt[],
  gameLetters: GameLetter[]
): Pick<GameStateShape, "attempts" | "gameLetters"> {
  const newAttempts = [...attempts];
  newAttempts[index] = attempt;

  const newGameLetters = gameLetters.map<GameLetter>((gameLetter) => {
    const isAttemptTarget = newAttempts.some((att) =>
      att.some((l) => l?.value === gameLetter.letter)
    );
    if (!isAttemptTarget)
      return gameLetter.positions.some((p) => p.state !== "unknown") ||
        gameLetter.eliminated ||
        gameLetter.instanceCount
        ? resetGameLetter(gameLetter)
        : gameLetter;

    const isDiscovered = newAttempts.some((att) =>
      att.some((al) => isAttemptFoundMatch(gameLetter, al))
    );
    const eliminated = !isDiscovered;
    const positions = !isDiscovered
      ? (getLetterPositions().map((p) => ({
          ...p,
          state: "incorrect",
        })) as LetterPositions)
      : (gameLetter.positions.map((pos, posInd) => {
          const attemptState = newAttempts.find(
            (att) => att[posInd]?.value === gameLetter.letter
          )?.[posInd];
          if (!attemptState)
            return {
              ...pos,
              state: "unknown",
            };

          return ["discovered", "eliminated"].includes(attemptState.result)
            ? { ...pos, state: "incorrect" }
            : attemptState.result === "correct"
            ? { ...pos, state: "correct" }
            : pos;
        }) as LetterPositions);

    const instanceCount = newAttempts.reduce(
      (total, att) =>
        Math.max(
          att?.filter((al) => isAttemptFoundMatch(gameLetter, al)).length || 0,
          total
        ),
      0
    );

    const guessOrders = [];
    let guessOrder = 0;
    while (guessOrders.length < instanceCount) {
      for (const att of newAttempts) {
        for (const al of att) {
          const isLetter = al?.value === gameLetter.letter;
          if (["discovered", "correct"].includes(al?.result || "")) {
            if (isLetter) {
              guessOrders.push(guessOrder);
              guessOrder = 0;
            } else {
              guessOrder += 1;
            }
          }
        }
      }
    }

    const newGameLetter: GameLetter = {
      ...gameLetter,
      eliminated,
      positions,
      instanceCount,
      guessOrders,
    };

    return objectHasMatch(newGameLetter, gameLetter)
      ? gameLetter
      : newGameLetter;
  });

  return {
    attempts: newAttempts,
    gameLetters: newGameLetters.every((gl, ind) => gl === gameLetters[ind])
      ? gameLetters
      : newGameLetters,
  };
}
