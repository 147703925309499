import { GameStateShape } from "../GameStateContext";
import {
  AlphabetLetter,
  gameLetters as defaultGameLetters,
  GameLetter,
  LetterPositions,
  LimitedArray,
  getLetterPositions,
  addGuessOrder,
} from "../gameStateDataStructures";

export function updateDiscoveredLetterPosition(
  gameState: Partial<GameStateShape>,
  letter: AlphabetLetter,
  position: number,
  incorrect: boolean
): Partial<GameStateShape> {
  const { gameLetters: originalGameLetters = defaultGameLetters } = gameState;
  const targetLetter = originalGameLetters.find((l) => l.letter === letter);
  if (!targetLetter) return gameState;

  const positions = targetLetter.positions || getLetterPositions();
  positions[position] = incorrect
    ? { state: "incorrect" }
    : { state: "unknown" };

  const newLetter: GameLetter = {
    ...targetLetter,
    positions,
    instanceCount: Math.max(targetLetter.instanceCount, 1),
  };

  return {
    ...gameState,
    gameLetters: [
      ...originalGameLetters.filter((l) => l.letter !== letter),
      newLetter,
    ],
  };
}

function updateLetterCount(
  gameLetter: GameLetter,
  letterCount: number,
  guessOrder?: number
) {
  const newTargetLetter = addGuessOrder(
    {
      ...gameLetter,
      eliminated: false,
      instanceCount: letterCount,
    },
    guessOrder
  );

  newTargetLetter.guessOrders = newTargetLetter.guessOrders.slice(
    0,
    letterCount
  );

  return newTargetLetter;
}

export function updateDiscoveredLetterCount(
  gameState: Partial<GameStateShape>,
  letter: AlphabetLetter,
  letterCount: number,
  guessOrder?: number
) {
  const { gameLetters: originalGameLetters = defaultGameLetters } = gameState;
  const targetLetter = originalGameLetters.find((l) => l.letter === letter);
  if (!targetLetter) return gameState;

  const newLetter = updateLetterCount(targetLetter, letterCount, guessOrder);

  const gameLetters = [
    ...originalGameLetters.filter((l) => l.letter !== letter),
    newLetter,
  ];

  return { ...gameState, gameLetters };
}

export function getDiscoveredLetters(gameLetters?: GameLetter[]): GameLetter[] {
  return (gameLetters || defaultGameLetters)?.filter((gl) => gl.instanceCount);
}

export type DiscoveredLetter = {
  letter: Maybe<AlphabetLetter>;
  wrongPositions: LimitedArray<boolean | null>;
  hintedPosition?: Maybe<number>;
};

export function deriveGameLettersFromDiscovered(
  discoveredLetters: Maybe<LimitedArray<DiscoveredLetter>>,
  gameLetters: GameLetter[]
) {
  if (!discoveredLetters) return gameLetters;

  let currentGuessOrder = 0;
  const newGameLetters = gameLetters.map((gl) => {
    const discoveredLetter = discoveredLetters.find(
      (dl) => dl?.letter === gl.letter
    );
    if (!discoveredLetter) return gl;

    const instanceCount = discoveredLetters.filter(
      (dl) => dl?.letter === gl.letter
    ).length;

    const eliminatedPositions = discoveredLetters
      .filter((dl) => dl?.letter === gl.letter)
      .flatMap((dl) => dl?.wrongPositions.map((wp, i) => (wp ? i : null)))
      .filter((i): i is number => i !== null);

    const letterInstance = updateLetterCount(
      gl,
      instanceCount,
      currentGuessOrder
    );
    currentGuessOrder += instanceCount;

    letterInstance.positions = (() => {
      const instPositions = [...letterInstance.positions];
      eliminatedPositions.forEach((pos) => {
        instPositions[pos] = { state: "incorrect" };
      });
      return instPositions as LetterPositions;
    })();

    return letterInstance;
  });

  return newGameLetters;
}
