import React, { useEffect, useState } from "react";
import focusableSelectors from "focusable-selectors";

type FocusableElement = Element & Pick<HTMLElement, "focus">;

const FocusableContext = React.createContext<{
  focusablesActive: boolean;
  setFocusablesActive: React.Dispatch<React.SetStateAction<boolean>>;
}>({ focusablesActive: true, setFocusablesActive: () => ({}) });

export const FocusableProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [focusablesActive, setFocusablesActive] = useState(true);
  return (
    <FocusableContext.Provider
      value={{ focusablesActive, setFocusablesActive }}
    >
      {children}
    </FocusableContext.Provider>
  );
};

export const useFocusableContext = () => React.useContext(FocusableContext);

const allFocusables = focusableSelectors.join(", ");

const isVisible = (el: Element): el is HTMLElement => {
  if (!(el as HTMLElement).offsetParent) return false;

  const style = window.getComputedStyle(el);
  return style.display !== "none" && style.visibility !== "hidden";
};

const getFocusables = (el: HTMLElement): HTMLElement[] =>
  [...el.querySelectorAll(allFocusables)].filter<HTMLElement>(isVisible);

const findSectionAwayFrom = (
  currentSection: Maybe<HTMLElement>,
  direction: "up" | "down"
) => {
  const allSections = [...document.querySelectorAll("section")].filter(
    isVisible
  );
  const foundIndex =
    (currentSection &&
      allSections.findIndex((sec) => currentSection.isSameNode(sec))) ??
    -1;
  const currentIndex =
    foundIndex > -1 ? foundIndex : direction === "up" ? allSections.length : -1;
  if (direction === "up") {
    if (currentIndex === 0) return allSections[allSections.length - 1];
    let sectionIterator = 1;
    let nextSection = allSections[currentIndex - 1];
    while (nextSection.contains(currentSection || null)) {
      sectionIterator += 1;
      nextSection = allSections[currentIndex - sectionIterator];
    }
    return nextSection;
  }
  if (direction === "down") {
    if (currentIndex === allSections.length - 1) return allSections[0];
    return allSections[currentIndex + 1];
  }
  return null;
};

export const getNextFocusable = (direction: "forward" | "back" = "forward") => {
  const focusableElements = getFocusables(document.body);
  const currentElement = document.activeElement;
  const foundIndex =
    (currentElement &&
      focusableElements.findIndex((el) => currentElement.isSameNode(el))) ??
    -1;
  const currentIndex =
    foundIndex > -1
      ? foundIndex
      : direction === "forward"
      ? -1
      : focusableElements.length;

  if (direction === "forward") {
    if (currentIndex === focusableElements.length - 1)
      return focusableElements[0];
    return focusableElements[currentIndex + 1];
  }
  if (direction === "back") {
    if (currentIndex === 0)
      return focusableElements[focusableElements.length - 1];
    return focusableElements[currentIndex - 1];
  }
};

export default function useFocusables() {
  const [selectedSection, setSelectedSection] =
    useState<Maybe<HTMLElement>>(null);

  const { focusablesActive } = useFocusableContext();

  useEffect(() => {
    if (!focusablesActive) return;

    const keyListener = (e: KeyboardEvent) => {
      let nextFocusable: Maybe<Element>;
      switch (e.key) {
        case "ArrowUp":
          setSelectedSection((oldSection) => {
            return findSectionAwayFrom(oldSection, "up");
          });
          return;
        case "ArrowDown":
          setSelectedSection((oldSection) => {
            return findSectionAwayFrom(oldSection, "down");
          });
          return;
        case "ArrowLeft":
          nextFocusable = getNextFocusable("back");
          break;
        case "ArrowRight":
          nextFocusable = getNextFocusable("forward");
          break;
        default:
          return;
      }

      if (nextFocusable) {
        (nextFocusable as HTMLElement)?.focus();
        setSelectedSection(nextFocusable?.closest("section"));
        e.preventDefault();
      }
    };
    window.addEventListener("keydown", keyListener);
    return () => window.removeEventListener("keydown", keyListener);
  }, [focusablesActive]);

  useEffect(() => {
    if (!focusablesActive) return;

    const focusListener = () => {
      if (!document.activeElement) return;
      const activeSection = document.activeElement.closest("section");
      setSelectedSection(activeSection);
    };
    window.addEventListener("focus", focusListener, true);

    return () => window.removeEventListener("focus", focusListener, true);
  }, [focusablesActive]);

  useEffect(() => {
    if (!selectedSection || !focusablesActive) return;
    if (document.activeElement?.closest("section")?.isSameNode(selectedSection))
      return;

    const nextSelectable = getFocusables(selectedSection)[0];
    if (document.activeElement?.isSameNode(nextSelectable)) return;

    (nextSelectable as Maybe<FocusableElement>)?.focus();
  }, [selectedSection, focusablesActive]);
}
