import { defaultGameState, GameStateShape } from "./GameStateContext";
import { deriveGameLettersFromCorrect } from "./game-state/correctLetters";
import {
  DiscoveredLetter,
  deriveGameLettersFromDiscovered,
} from "./game-state/discoveredLetters";
import {
  gameLetters as originalLetters,
  GameLetter,
  AlphabetLetter,
  LimitedArray,
  LetterPositions,
  getLetterPositions,
} from "./gameStateDataStructures";
import { getGuesses } from "./guessCalculations";

interface LegacyGameState extends GameStateShape {
  eliminatedLetters: Maybe<AlphabetLetter[]>;
  discoveredLetters: Maybe<LimitedArray<DiscoveredLetter>>;
  correctLetters: Maybe<LimitedArray<AlphabetLetter | null>>;
}

const gameStateKey = "gameState";

function deriveGameLetters(gameState: Partial<LegacyGameState>): GameLetter[] {
  let gameLetters = [...originalLetters];

  gameState.eliminatedLetters?.forEach((eliminatedLetter) => {
    const letter = gameLetters.find((gl) => gl.letter === eliminatedLetter);
    if (!letter) return;

    letter.eliminated = true;
  });

  gameLetters = deriveGameLettersFromDiscovered(
    gameState.discoveredLetters,
    gameLetters
  );

  gameLetters = deriveGameLettersFromCorrect(
    gameState.correctLetters,
    gameLetters
  );

  return gameLetters;
}

export type LetterInstance = {
  letter: AlphabetLetter;
  positions: LetterPositions;
  eliminated?: boolean;
  guessOrder?: number;
};

type OldGameLetter = GameLetter & {
  instances: LetterInstance[];
};

const isOldGameLetter = (
  possibleGameLetter: unknown
): possibleGameLetter is OldGameLetter =>
  typeof (possibleGameLetter as OldGameLetter | undefined)?.instances ===
  "object";

function transitionFromInstances(
  gameLetters: (OldGameLetter | GameLetter)[]
): GameLetter[] {
  let orderCount = -1;
  return gameLetters.map((gl) => {
    if (!isOldGameLetter(gl)) return gl;

    return {
      ...gl,
      instanceCount: gl.instances.length,
      positions: gl.instances[0]?.positions || getLetterPositions(),
      guessOrders: [
        ...new Set(
          gl.instances.map((inst) =>
            inst.guessOrder
              ? (orderCount = Math.max(inst.guessOrder, inst.guessOrder))
              : (orderCount += 1)
          )
        ),
      ],
    };
  });
}

export const getStoredGameState = (): Partial<GameStateShape> => {
  const possibleGameState = JSON.parse(
    window.localStorage.getItem(gameStateKey) || "null"
  );
  if (!possibleGameState) return defaultGameState;

  if (typeof possibleGameState !== "object") return defaultGameState;
  if (possibleGameState === null) return defaultGameState;
  if (Array.isArray(possibleGameState)) return defaultGameState;

  const parsedGameState = possibleGameState as Partial<GameStateShape>;

  const originalGameState: Partial<GameStateShape> = {
    ...defaultGameState,
    ...parsedGameState,
  };

  if (!parsedGameState.gameLetters) {
    originalGameState.gameLetters = deriveGameLetters(parsedGameState);
  } else {
    originalGameState.gameLetters = transitionFromInstances(
      parsedGameState.gameLetters
    );
  }

  originalGameState.guesses = getGuesses({
    ...possibleGameState,
    ...originalGameState,
  });

  return originalGameState;
};

export const storeGameState = (gameState: Partial<GameStateShape>) => {
  window.localStorage.setItem(gameStateKey, JSON.stringify(gameState));
};
