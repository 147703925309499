import type { GameStateShape } from "../GameStateContext";
import {
  AlphabetLetter,
  GameLetter,
  LetterPositions,
  LimitedArray,
  positionHasState,
} from "../gameStateDataStructures";
import { getAllIndexesOf } from "../jsUtils";

export type CorrectLetter = AlphabetLetter | null;

export function addCorrectLetter(gl: GameLetter, ind: number): GameLetter {
  const positions = gl.positions.map((pos, i) => ({
    ...pos,
    state: i === ind ? "correct" : pos.state,
  })) as LetterPositions;
  const correctCount = positions.filter((p) =>
    positionHasState(p, "correct")
  ).length;

  const instanceCount =
    gl.instanceCount < correctCount ? gl.instanceCount + 1 : gl.instanceCount;

  return {
    ...gl,
    instanceCount,
    positions,
  };
}

export function removeCorrectLetter(gl: GameLetter, ind: number): GameLetter {
  const positions = gl.positions.map((pos, i) => ({
    ...pos,
    state: i === ind ? "unknown" : pos.state,
  })) as LetterPositions;

  return {
    ...gl,
    positions,
  };
}

const gameLetterIsCorrect = (gl: GameLetter, includeHinted = false) =>
  gl.positions.some(
    (p) => p.state === "correct" || (includeHinted && p.state === "hinted")
  );

export function getCorrectLetters(
  gameLetters: GameLetter[],
  includeHinted = false
) {
  return gameLetters.filter((gl) => gameLetterIsCorrect(gl, includeHinted));
}

export function extractCorrectLetters(
  gameLetters: Maybe<GameLetter[]>
): LimitedArray<CorrectLetter>;
export function extractCorrectLetters(
  gameState: Partial<GameStateShape>
): LimitedArray<CorrectLetter>;
export function extractCorrectLetters(
  ...args: [Maybe<GameLetter[]>] | [Partial<GameStateShape>]
) {
  const [gameState] = args;
  const gameLetters =
    (gameState as Partial<GameStateShape>)?.gameLetters ||
    (gameState as Maybe<GameLetter[]>);

  return new LimitedArray(
    ...[
      ...(gameLetters?.reduce(
        (correct, gl) => {
          const hasCorrect = gameLetterIsCorrect(gl);
          if (!hasCorrect) return correct;

          const newCorrectIndexes = gl.positions.reduce<number[]>(
            (correctIndexes, pos, ind) =>
              pos.state !== "correct"
                ? correctIndexes
                : [...correctIndexes, ind],
            []
          );
          const newCorrect = correct.map((c, ind) =>
            newCorrectIndexes.includes(ind) ? gl.letter : c
          );
          return newCorrect;
        },
        [null, null, null, null, null] as CorrectLetter[]
      ) || [null, null, null, null]),
    ]
  );
}

export function deriveGameLettersFromCorrect(
  correctLetters: Maybe<LimitedArray<CorrectLetter>>,
  gameLetters: GameLetter[]
): GameLetter[] {
  return gameLetters.map((gl) => {
    if (!correctLetters?.includes(gl.letter)) return gl;

    const correctGameLetter = getAllIndexesOf(
      correctLetters,
      gl.letter
    ).reduce<GameLetter>(addCorrectLetter, gl);

    return correctGameLetter;
  });
}
