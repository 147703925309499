import { useCallback, useEffect, useRef, useState } from "react";

export default function useFocusLossPrevention() {
  const clickTarget = useRef<HTMLElement | null>(null);
  const lastActiveElement = useRef<HTMLElement | undefined>();
  const [allFocusPreventers, setAllFocusPreventers] = useState<HTMLElement[]>(
    [],
  );
  const clearLastActiveTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const clearLastActive = useCallback(() => {
    clearLastActiveTimeout.current = setTimeout(() => {
      if (document.activeElement !== document.body) return;
      lastActiveElement.current = undefined;
    }, 15);
  }, []);

  const refocusLastActive = useCallback<(ev: MouseEvent | TouchEvent) => void>(
    (ev) => {
      lastActiveElement.current?.focus();
    },
    [],
  );

  const lossPreventerEnter = useCallback<(ev: MouseEvent | TouchEvent) => void>(
    (ev) => {
      clickTarget.current = ev.target as HTMLElement;
    },
    [],
  );

  const lossPreventerLeave = useCallback<(ev: MouseEvent | TouchEvent) => void>(
    (ev) => {
      clickTarget.current = null;
    },
    [],
  );

  useEffect(
    function addClickListeners() {
      allFocusPreventers.forEach((preventer) => {
        preventer.addEventListener("click", refocusLastActive);
        preventer.addEventListener("mouseenter", lossPreventerEnter);
        preventer.addEventListener("mouseleave", lossPreventerLeave);
      });

      return () => {
        allFocusPreventers.forEach((preventer) => {
          preventer.removeEventListener("click", refocusLastActive);
          preventer.removeEventListener("mouseenter", lossPreventerEnter);
          preventer.removeEventListener("mouseleave", lossPreventerLeave);
        });
      };
    },
    [
      allFocusPreventers,
      refocusLastActive,
      lossPreventerEnter,
      lossPreventerLeave,
    ],
  );

  useEffect(function trackLastActive() {
    const handleFocusLeave = (ev: FocusEvent) => {
      if (clickTarget.current?.hasAttribute("data-prevent-focus-loss")) {
        ev.preventDefault();
        ev.stopPropagation();
      }
      clearLastActive();
    };

    const focusListener: EventListener = (evt) => {
      const ev = evt as FocusEvent;
      clearTimeout(clearLastActiveTimeout.current ?? 0);
      if (ev.target === clickTarget.current) {
        handleFocusLeave(ev);
        return;
      }

      if (ev.target === window || ev.target === document.body) {
        clearLastActive();
        return;
      }

      lastActiveElement.current = ev.target as HTMLElement;
    };

    const focusOutListener: EventListener = (ev) => {
      handleFocusLeave(ev as FocusEvent);
    };

    const blurListener: EventListener = (evt) => {
      const ev = evt as FocusEvent;
      handleFocusLeave(ev);
    };

    const addListeners = () => {
      window.addEventListener("focus", focusListener, true);
      window.addEventListener("blur", blurListener, true);
      window.addEventListener("focusout", focusOutListener, true);
    };

    const removeListeners = () => {
      window.removeEventListener("focus", focusListener, true);
      window.removeEventListener("blur", blurListener, true);
      window.removeEventListener("focusout", focusOutListener, true);
    };

    function getNewFocusPreventers() {
      setAllFocusPreventers((oldPreventers) => {
        const preventers = Array.from<HTMLElement>(
          document.querySelectorAll("[data-prevent-focus-loss]"),
        );
        const hasNew = preventers.some(
          (preventer) => !oldPreventers.includes(preventer),
        );
        return hasNew ? preventers : oldPreventers;
      });
    }

    addListeners();
    getNewFocusPreventers();

    const childrenObserver = new MutationObserver(() => {
      getNewFocusPreventers();
    });
    childrenObserver.observe(document.body, { childList: true, subtree: true });

    return () => {
      removeListeners();
      // inputs.forEach(removeListeners);
      childrenObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("mouseenter", ({ target }) => {
      console.log({ target });
    });
  }, []);
}
