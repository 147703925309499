import type { GameStateShape } from "../GameStateContext";
import { GameLetter, LetterPositions } from "../gameStateDataStructures";

const getHintedPositions = (newState: GameStateShape): GameStateShape => {
  const { gameLetters } = newState;
  const discoveredGameLetters =
    gameLetters?.filter((gl) => gl.instanceCount) || [];
  const correctGameLetters =
    gameLetters?.filter((gl) =>
      gl.positions.some((p) => p.state === "correct")
    ) || [];
  const letterPositions: (string | undefined)[] = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ];
  correctGameLetters.forEach((gl) => {
    gl.positions.forEach((p, i) => {
      if (p.state === "correct") {
        letterPositions[i] = gl.letter;
      }
    });
  });

  let changed = true;
  let hintedGameLetters: GameLetter[] = [];

  while (changed) {
    const originalHinted = [...hintedGameLetters];
    hintedGameLetters = [
      ...hintedGameLetters,
      ...discoveredGameLetters
        .map<GameLetter | null>((gameLetter) => {
          const instancePositions = gameLetter.positions || [];
          const correctCount =
            gameLetter.positions?.filter((p, i) => p.state === "correct")
              .length || 0;
          const uncertainCount = gameLetter.instanceCount - correctCount;
          const possiblePositionCount = Array(5)
            .fill(null)
            .map(
              (_, i) =>
                letterPositions[i] ||
                (instancePositions[i].state === "correct" ||
                instancePositions[i].state === "incorrect"
                  ? instancePositions[i]
                  : null)
            )
            .filter((p) => !p).length;
          if (
            !possiblePositionCount ||
            possiblePositionCount !== uncertainCount
          )
            return null;

          return {
            ...gameLetter,
            positions: gameLetter.positions.map((p, i) => {
              if (p.state === "correct" || p.state === "incorrect") return p;
              letterPositions[i] = gameLetter.letter;
              return {
                ...p,
                state: "hinted",
              };
            }) as LetterPositions,
          };
        })
        .filter<GameLetter>((l): l is GameLetter => Boolean(l)),
    ];
    changed = originalHinted.length !== hintedGameLetters.length;
  }

  return {
    ...newState,
    gameLetters: [
      ...(gameLetters?.filter(
        (gl) => !hintedGameLetters.some((hl) => hl?.letter === gl.letter)
      ) || []),
      ...hintedGameLetters,
    ],
  };
};

export default getHintedPositions;
