export const getUniqueId = (length = 5) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const uniqueId = new Array(length)
    .fill("")
    .reduce(
      (uId) =>
        uId + characters.charAt(Math.floor(Math.random() * characters.length)),
      ""
    );
  return uniqueId;
};
