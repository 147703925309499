import React from "react";
import styled from "styled-components";
import { callRefs } from "./reactUtils";

const SectionInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const AppSectionDisplay = styled.div`
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 20em;
  border-left: 1px solid rgb(var(--highlight-color-rgb) / 45%);
  border-right: 1px solid rgb(var(--highlight-color-rgb) / 45%);

  align-items: stretch;
  min-width: 0;
  flex: 1;
  min-height: calc(100% - var(--header-height));

  body.ReactModal__Body--open & {
    overflow: hidden;
  }
`;

type AppSectionProps = Omit<JSX.IntrinsicElements["div"], "ref">;

const AppSection = React.forwardRef<HTMLDivElement, AppSectionProps>(
  function AppSectionInner({ children, ...props }, ref) {
    return (
      <AppSectionDisplay {...props} ref={(val) => callRefs(val, ref)}>
        <SectionInner>{children}</SectionInner>
      </AppSectionDisplay>
    );
  },
);

export default AppSection;
