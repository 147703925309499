import tinycolor, { ColorInputWithoutInstance } from "tinycolor2";

export type CSSVariable = `var(--${string})`;

export const getCSSBodyVariable = (name: CSSVariable) => {
  const variableName = name.replace(/^var\(/, "").replace(/\)$/, "");
  const value = getComputedStyle(document.body).getPropertyValue(variableName);
  return value;
};

export type StringColorInput = Extract<ColorInputWithoutInstance, string>;

export const getCSSColor = (color: StringColorInput | CSSVariable) => {
  if (!color.match(/^var\(/)) {
    return tinycolor(color);
  }

  const varColor = getCSSBodyVariable(color as CSSVariable);
  return tinycolor(varColor);
};

export const getAlphaColor = (
  color: StringColorInput | CSSVariable,
  alpha: number
) => `color: ${getCSSColor(color).setAlpha(alpha).toRgbString()};`;
