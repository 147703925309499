import { css } from "styled-components";

export const globalCssVars = css`
  --our-white: #f9f7f0;
  --our-white-rgb: 249 247 240;
  --our-black: #090d1c;
  --our-black-rgb: 9 13 28;
  --base-font: ff-basic-gothic-pro, sans-serif;
  --block-letter-font-weight: 900;
  --highlight-color-rgb: 132 146 207;
  --discovered-color: #f2cb05;
  --correct-color: #519645;
  --danger-base-color: #a31818;
  --desktop-width: 1280px;
  --header-height: 74px;

  --light-background-color: var(--our-white);
  --light-base-text-color: var(--our-black);
  --light-base-text-rgb: var(--our-black-rgb);
  --light-hint-text: #545454;
  --light-base-danger-text: #a31818;
  --light-highlight-color: #394a93;

  --dark-background-color: var(--our-black);
  --dark-base-text-color: var(--our-white);
  --dark-base-text-rgb: var(--our-white-rgb);
  --dark-hint-text: #9f9f9f;
  --dark-base-danger-text: rgb(233 107 107);
  --dark-highlight-color: #8492cf;

  --background-color: var(--dark-background-color);
  --opposite-background-color: var(--light-background-color);
  --text-color: var(--dark-base-text-color);
  --hint-text: var(--dark-hint-text);
`;

export const darkTheme = css`
  --text-color: var(--dark-base-text-color);
  --opposite-text-color: var(--light-base-text-color);
  --text-color-rgb: var(--dark-base-text-rgb);

  --background-color: var(--dark-background-color);
  --background-color-rgb: var(--our-black-rgb);
  --opposite-background-color: var(--light-background-color);

  --danger-color: var(--danger-base-color);
  --danger-text-color: var(--dark-base-danger-text);

  --highlight-color: var(--dark-highlight-color);
  --opposite-highlight-color: var(--light-highlight-color);

  --hint-text: var(--dark-hint-text);
`;

export const lightTheme = css`
  --text-color: var(--light-base-text-color);
  --opposite-text-color: var(--dark-base-text-color);
  --text-color-rgb: var(--light-base-text-rgb);

  --background-color: var(--light-background-color);
  --background-color-rgb: var(--our-white-rgb);
  --opposite-background-color: var(--dark-background-color);

  --danger-color: var(--danger-base-color);
  --danger-text-color: var(--light-base-danger-text);

  --highlight-color: var(--light-highlight-color);
  --opposite-highlight-color: var(--dark-highlight-color);

  --hint-text: var(--light-hint-text);
`;
