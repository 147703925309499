export function callRefs<T>(
  value: Maybe<T>,
  ...refs: Maybe<SettableRef<Maybe<T>>>[]
) {
  refs.forEach((ref) => {
    if (typeof ref === "function") {
      ref(value);
    } else if (ref) {
      ref.current = value;
    }
  });
}
