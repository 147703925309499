import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { GameStateContext } from "./GameStateContext";
import { LetterInput } from "./LetterInput";
import styled from "styled-components";
import {
  focusStyles,
  LetterButtonDisplayLooseProps,
} from "./LetterButtonDisplay";
import { getLetter, isAlphabetLetter } from "./gameStateDataStructures";
import { getDiscoveredLetters } from "./game-state/discoveredLetters";
import {
  extractCorrectLetters,
  CorrectLetter,
} from "./game-state/correctLetters";

const CorrectLetterInput = styled(LetterInput)`
  font-size: 1.75em;
`;

const CorrectLetterContainer = styled.section<LetterButtonDisplayLooseProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding: 0.25em;
  border 1px solid transparent;

  &:focus-within {
    border-cplor: var(--highlight-color);
    & ${CorrectLetterInput} {
      ${focusStyles}
    }
  }
`;

export default function CorrectLettersSection() {
  const {
    state: { gameLetters },
    removeCorrectLetter,
    addCorrectLetter,
  } = useContext(GameStateContext);
  const discoveredLetters = useMemo(
    () => getDiscoveredLetters(gameLetters),
    [gameLetters]
  );
  const correctLetters = useMemo(
    () => extractCorrectLetters(gameLetters),
    [gameLetters]
  );
  const [nextFocus, setNextFocus] = useState<number | null>(null);

  const removeOldValue = useCallback(
    (oldValue: CorrectLetter, i: number) => {
      if (!isAlphabetLetter(oldValue)) return;

      const oldGameLetter = getLetter(oldValue, gameLetters || []);
      if (!oldGameLetter) return;
      removeCorrectLetter({ gameLetter: oldGameLetter, position: i });
    },
    [gameLetters, removeCorrectLetter]
  );

  const addNewValue = useCallback(
    (newValue: CorrectLetter, i: number) => {
      if (!isAlphabetLetter(newValue)) return;

      const correctLetter = getLetter(newValue, gameLetters || []);

      if (!correctLetter) return;

      addCorrectLetter({
        gameLetter: correctLetter,
        position: i,
      });
    },
    [gameLetters, addCorrectLetter]
  );

  const updateCorrectLetter = useCallback(
    (e: ChangeEvent<HTMLInputElement>, i: number) => {
      if (e.target.value.length > 1) return;

      const oldValue = correctLetters[i];
      const newValue = e.target.value.split("").pop()?.toUpperCase() || "";

      if (oldValue === newValue) return;

      removeOldValue(oldValue, i);

      if (!isAlphabetLetter(newValue)) return;
      addNewValue(newValue, i);

      const nextFocusNum = Math.min(i + 1, 4);
      setTimeout(() => setNextFocus(nextFocusNum), 0);
    },
    [correctLetters, removeOldValue, addNewValue]
  );

  const backspace = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, i: number) => {
      if (e.key === "Delete") {
        removeOldValue(correctLetters[i], i);
        return;
      }

      if (e.key !== "Backspace") return;

      if (correctLetters[i]) {
        removeOldValue(correctLetters[i], i);
        setNextFocus(i);
        return;
      }

      const prevInd = Math.max(i - 1, 0);
      removeOldValue(correctLetters[prevInd], prevInd);
      setTimeout(() => setNextFocus(prevInd), 0);
    },
    [correctLetters, removeOldValue]
  );

  const finalLetters = correctLetters.map(
    (cl, i) =>
      (!cl &&
        discoveredLetters?.find((inst) => inst.positions[i]?.state === "hinted")
          ?.letter) ||
      cl
  );

  return (
    <>
      {finalLetters.map((l, i) => {
        const isHinted = correctLetters[i] !== l;

        let label = isHinted ? `probably ${l}` : "unknown";
        label = l || label;

        const ref =
          nextFocus === i
            ? (el: HTMLInputElement | null) => {
                if (!el) return;
                setTimeout(function focusAfterRender() {
                  el.focus();
                }, 0);
                setNextFocus(null);
              }
            : null;

        return (
          <CorrectLetterContainer
            key={`${correctLetters.join("")}-${i}`}
            isCorrect
          >
            <CorrectLetterInput
              isCorrect
              isHinted={isHinted}
              placeholder={(isHinted ? l : "") || ""}
              letter={l}
              ref={ref}
              onChange={(e) => updateCorrectLetter(e, i)}
              onKeyDown={(e) => backspace(e, i)}
              aria-label={`Correct Letter ${i + 1}: ${label}.`}
              title={`Correct Letter ${i + 1}: ${label}.`}
            />
          </CorrectLetterContainer>
        );
      })}
    </>
  );
}
