import React, { useContext, useEffect, useState } from "react";
import { GameStateContext, ResetState } from "./GameStateContext";
import styled, { css } from "styled-components";

const buttonPressedStyles = css`
  box-shadow: inset 2px 2px rgba(0 0 0 / 45%);
  outline: 1px solid var(--highlight-color);
`;

export const UnstyledButton = styled.button.attrs({
  type: "button",
})<object>`
  appearance: none;
  border: 0;
  display: block;
  box-shadow: none;
  outline: none;
  margin: 0;
`;

const ActionButton = styled(UnstyledButton)`
  font-weight: 600;
  padding: 0.5em;
`;

const LargeButton = styled(ActionButton)`
  font-size: 1.25em;
  line-height: 1;
  padding: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.15em;

  &:focus {
    outline: 1px solid var(--highlight-color);
  }

  &:active {
    ${buttonPressedStyles}
  }
`;

const LargeButtonContainer = styled.section`
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const destructiveButtonStyles = css`
  color: var(--dark-base-text-color);
  background-color: var(--danger-color);
`;

export const DestructiveButton = styled(ActionButton)`
  ${destructiveButtonStyles}
`;

const constructiveButtonStyles = css`
  color: var(--dark-base-text-color);
  background-color: var(--correct-color);
`;

export const ConstructiveButton = styled(ActionButton)`
  ${constructiveButtonStyles}
`;

type ResetButtonDisplayProps = { resetState: ResetState };

const ResetButtonDisplay = styled(LargeButton)<ResetButtonDisplayProps>`
  ${destructiveButtonStyles}
  ${({ resetState }) => resetState === "started" && buttonPressedStyles}
`;

const AttemptButtonContainer = styled(LargeButtonContainer)`
  margin-top: 0.8em;
  flex: 0 0 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AttemptButtonDisplay = styled(LargeButton)`
  ${constructiveButtonStyles}
  --right-bottom: calc(100% - 10px);
  clip-path: polygon(0 0, 100% 0, var(--right-bottom) 100%, 10px 100%);
  flex-grow: 1;

  ${({ theme }) => theme.desktopQuery} {
    max-width: 50%;
  }
`;

type AddAttemptProps = React.PropsWithoutRef<
  Omit<JSX.IntrinsicElements["button"], "type">
>;

export function AddAttemptButton(props: AddAttemptProps) {
  return (
    <AttemptButtonContainer>
      <AttemptButtonDisplay {...props}>Add Attempt</AttemptButtonDisplay>
    </AttemptButtonContainer>
  );
}

type ResetButtonProps = React.PropsWithoutRef<
  JSX.IntrinsicElements["section"]
> & {
  buttonProps?: React.PropsWithoutRef<
    Omit<JSX.IntrinsicElements["button"], "type">
  >;
};

export function ResetButton({
  buttonProps = {},
  ...containerProps
}: ResetButtonProps) {
  const {
    reset,
    state: { resetState },
  } = useContext(GameStateContext);

  return (
    <LargeButtonContainer {...containerProps}>
      <ResetButtonDisplay
        onClick={reset}
        resetState={resetState}
        {...buttonProps}
      >
        {resetState === "started" ? `Yes, I'm sure` : "Reset"}
      </ResetButtonDisplay>
    </LargeButtonContainer>
  );
}

const triangleSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351 351" style="enable-background:new 0 0 351 351" xml:space="preserve"><path d="M-4.5-4.5v360h360l-12.2-12.2L8 8-4.5-4.5z" style="fill:{FILL_COLOR}"/></svg>`;

const getTriangle = (highlightColor: string) => {
  const svg = triangleSvg.replace("{FILL_COLOR}", highlightColor);
  return css`
    background-image: url("data:image/svg+xml,${encodeURIComponent(svg)}");
  `;
};

const buttonArrow = css<{ highlightColor?: string }>`
  content: " ";
  display: block;
  position: absolute;
  width: 1.35em;
  height: 1.35em;
  background-repeat: no-repeat;
  background-size: 125%;
  background-position: center;
  ${({ highlightColor }) => getTriangle(highlightColor || "")}
`;

export type PageToggleTargetContainerProps = Omit<
  JSX.IntrinsicElements["div"],
  "ref"
> & {
  right?: boolean;
  left?: boolean;
  highlightColor?: string;
};

export const PageToggleTargetContainerSection = React.forwardRef<
  HTMLDivElement,
  Omit<PageToggleTargetContainerProps, "highlightColor">
>(function TTCSInner(props, ref) {
  const [highlightColor, setHighlightColor] = useState(
    getComputedStyle(document.body).getPropertyValue("--highlight-color")
  );
  useEffect(() => {
    setInterval(
      () =>
        setHighlightColor(
          getComputedStyle(document.body).getPropertyValue("--highlight-color")
        ),
      500
    );
  }, []);
  return (
    <PageToggleTargetContainerSectionDisplay
      highlightColor={highlightColor}
      {...props}
      ref={ref}
    />
  );
});

export const PageToggleTargetContainerSectionDisplay = styled.div<PageToggleTargetContainerProps>`
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid var(--highlight-color);
  padding: 0.5em 0;

  section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100%;
  }

  section,
  section:focus-within {
    border: none;
  }

  ${({ right }) =>
    right &&
    css`
      justify-content: flex-start;

      p {
        text-align: right;
      }

      ${PageToggleButton} {
        border-top-right-radius: 0.3em;
        border-bottom-right-radius: 0.3em;
        border-left: none;
        margin-right: 2em;

        &::after {
          ${buttonArrow}
          transform-origin: bottom right;
          top: -1.45em;
          right: 0.04em;
          transform: rotate(225deg);
        }
      }
    `}

  ${({ left }) =>
    left &&
    css`
      justify-content: flex-end;

      ${PageToggleButton} {
        border-top-left-radius: 0.3em;
        border-bottom-left-radius: 0.3em;
        border-right: none;
        order: 2;
        margin-left: 2em;

        &::before {
          ${buttonArrow}
          transform-origin: top left;
          top: -0.085em;
          left: 0.035em;
          transform: rotate(45deg);
        }
      }
    `}
`;

export const PageToggleButton = styled(UnstyledButton)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 0.25em 0.45em;
  background-color: var(--highlight-color);
  color: var(--dark-base-text-color);
  text-shadow: 0 0 0.07em var(--light-base-text-color);
  text-transform: uppercase;
  letter-spacing: 0.075em;
  position: relative;
  font-weight: 600;

  &,
  &::after,
  &::before {
    box-sizing: border-box;
    border: 2px solid rgb(var(--text-color-rgb) / 45%);
  }

  &::before,
  &::after {
    border-top-color: transparent;
    border-right-color: transparent;
    background-position: center;
    background-size: 100%;
  }

  &:hover,
  &:focus {
    && {
      border-left-color: var(--highlight-color);
      border-right-color: var(--highlight-color);
    }

    &,
    &::after,
    &::before {
      border-color: var(--text-color);
    }

    &::before,
    &::after {
      border-top-color: transparent;
      border-right-color: transparent;
    }
  }
`;
