import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import {
  AlphabetLetter,
  Guesses,
  objectHasMatch,
  Attempt,
  emptyAttempt,
  GameLetter,
  gameLetters,
  deepCopy,
} from "./gameStateDataStructures";
import { getGuesses } from "./guessCalculations";
import { removeIncorrectGuesses } from "./guessUtils";
import { getStoredGameState, storeGameState } from "./storedGameState";
import {
  addEliminatedLetter,
  removeEliminatedLetter,
  setEliminatedLetters,
  toggleEliminatedLetter,
} from "./game-state/eliminatedLetters";
import {
  updateDiscoveredLetterCount,
  updateDiscoveredLetterPosition,
} from "./game-state/discoveredLetters";
import {
  addCorrectLetter,
  removeCorrectLetter,
} from "./game-state/correctLetters";
import { setAttempt } from "./game-state/attemptsActions";

import getHintedPositions from "./game-state/hintedPositions";

export type ResetState = Maybe<"started">;

export interface GameStateInnerShape {
  gameLetters: GameLetter[];
  resetState?: ResetState;
  attempts: Attempt[];
  guesses: Guesses;
}

export type GameStateShape = Partial<GameStateInnerShape>;

export const defaultGameState: GameStateShape = {
  gameLetters,
  attempts: [deepCopy(emptyAttempt)],
  guesses: {
    "00000": [],
  },
};

type UpdateDiscoveredLetterParams = Parameters<
  typeof updateDiscoveredLetterCount
>;
type UpdateDiscoveredLetterPayload = {
  letter: UpdateDiscoveredLetterParams[1];
  letterCount: UpdateDiscoveredLetterParams[2];
  guessOrder?: UpdateDiscoveredLetterParams[3];
};

type UpdateDiscoveredLetterPositionParams = Parameters<
  typeof updateDiscoveredLetterPosition
>;

type UpdateDiscoveredLetterPositionPayload = {
  letter: UpdateDiscoveredLetterPositionParams[1];
  position: UpdateDiscoveredLetterPositionParams[2];
  incorrect: UpdateDiscoveredLetterPositionParams[3];
};

type AddCorrectLetterParams = Parameters<typeof addCorrectLetter>;
type AddCorrectLetterPayload = {
  gameLetter: AddCorrectLetterParams[0];
  position: AddCorrectLetterParams[1];
};

type RemoveCorrectLetterParams = Parameters<typeof removeCorrectLetter>;
type RemoveCorrectLetterPayload = {
  gameLetter: RemoveCorrectLetterParams[0];
  position: RemoveCorrectLetterParams[1];
};

interface GameStateProviderShape {
  state: GameStateShape;
  setEliminatedLetters: (letter: Maybe<AlphabetLetter>[]) => void;
  addEliminatedLetter: (letter: AlphabetLetter) => void;
  removeEliminatedLetter: (letter: AlphabetLetter) => void;
  toggleEliminatedLetter: (letter: AlphabetLetter) => void;
  updateDiscoveredLetterCount: (payload: UpdateDiscoveredLetterPayload) => void;
  updateDiscoveredLetterPosition: (
    payload: UpdateDiscoveredLetterPositionPayload
  ) => void;
  addCorrectLetter: (payload: AddCorrectLetterPayload) => void;
  removeCorrectLetter: (payload: RemoveCorrectLetterPayload) => void;
  setAttempt: (attempt: Attempt, index: number) => void;
  addGuess: (guess: AddGuessPayload) => void;
  updateGuess: (guess: UpdateGuessPayload) => void;
  removeGuess: (guess: RemoveGuessPayload) => void;
  removeIncorrect: () => void;
  reset: () => void;
}

const noop = () => {
  // noop
};

export const GameStateContext = createContext<GameStateProviderShape>({
  state: defaultGameState,
  addEliminatedLetter: noop,
  removeEliminatedLetter: noop,
  toggleEliminatedLetter: noop,
  setEliminatedLetters: noop,
  updateDiscoveredLetterCount: noop,
  updateDiscoveredLetterPosition: noop,
  addCorrectLetter: noop,
  removeCorrectLetter: noop,
  setAttempt: noop,
  addGuess: noop,
  updateGuess: noop,
  removeGuess: noop,
  removeIncorrect: noop,
  reset: noop,
});

export const useGameState = () => useContext(GameStateContext);

const guessActions = [
  "addGuess",
  "updateGuess",
  "removeGuess",
  "removeIncorrect",
] as const;

type EliminationActions =
  | "addEliminatedLetter"
  | "removeEliminatedLetter"
  | "toggleEliminatedLetter";
type GuessActions = typeof guessActions[number];
type AttemptActions = "setAttempt";
type GameStateActions =
  | "init"
  | EliminationActions
  | AttemptActions
  | GuessActions
  | "setEliminatedLetters"
  | "updateDiscoveredLetterCount"
  | "updateDiscoveredLetterPosition"
  | "addCorrectLetter"
  | "removeCorrectLetter"
  | "reset";

type AddGuessPayload = { template: string; guess: string; final?: boolean };
type UpdateGuessPayload = AddGuessPayload & { index: number };
type RemoveGuessPayload = { template: string; index: number };
type AttemptPayload = { attempt: Attempt; index: number };

type GameStateReduceAction<T extends GameStateActions> =
  T extends EliminationActions
    ? { type: T; payload: AlphabetLetter }
    : T extends "init"
    ? { type: T; payload?: never }
    : T extends "setEliminatedLetters"
    ? { type: T; payload: Maybe<AlphabetLetter>[] }
    : T extends "updateDiscoveredLetterCount"
    ? { type: T; payload: UpdateDiscoveredLetterPayload }
    : T extends "updateDiscoveredLetterPosition"
    ? { type: T; payload: UpdateDiscoveredLetterPositionPayload }
    : T extends "addCorrectLetter"
    ? { type: T; payload: AddCorrectLetterPayload }
    : T extends "removeCorrectLetter"
    ? { type: T; payload: RemoveCorrectLetterPayload }
    : T extends "setAttempt"
    ? { type: T; payload: AttemptPayload }
    : T extends "addGuess"
    ? { type: T; payload: AddGuessPayload }
    : T extends "updateGuess"
    ? { type: T; payload: UpdateGuessPayload }
    : T extends "removeGuess"
    ? { type: T; payload: RemoveGuessPayload }
    : T extends "removeIncorrect"
    ? { type: T; payload: undefined }
    : T extends "reset"
    ? { type: T; payload: undefined }
    : never;

const gameStateReducer = <T extends GameStateActions>(
  state: GameStateShape,
  action: GameStateReduceAction<T>
): GameStateShape => {
  let newState = state;
  switch (action.type) {
    case "init":
      newState = { ...state };
      break;
    case "setEliminatedLetters":
      newState = setEliminatedLetters(state, action.payload);
      break;
    case "addEliminatedLetter":
      newState = addEliminatedLetter(state, action.payload);
      break;
    case "removeEliminatedLetter":
      newState = removeEliminatedLetter(state, action.payload);
      break;
    case "toggleEliminatedLetter":
      newState = toggleEliminatedLetter(state, action.payload);
      break;
    case "updateDiscoveredLetterCount":
      newState = updateDiscoveredLetterCount(
        state,
        action.payload.letter,
        action.payload.letterCount ?? 1,
        action.payload.guessOrder
      );
      break;
    case "updateDiscoveredLetterPosition":
      newState = updateDiscoveredLetterPosition(
        state,
        action.payload.letter,
        action.payload.position,
        action.payload.incorrect
      );
      break;
    case "addCorrectLetter":
      newState = {
        ...state,
        gameLetters: [
          ...(state.gameLetters?.filter(
            (gl) => gl.letter !== action.payload.gameLetter.letter
          ) || []),
          addCorrectLetter(action.payload.gameLetter, action.payload.position),
        ],
      };
      break;
    case "removeCorrectLetter":
      newState = {
        ...state,
        gameLetters: [
          ...(state.gameLetters?.filter(
            (gl) => gl.letter !== action.payload.gameLetter.letter
          ) || []),
          removeCorrectLetter(
            action.payload.gameLetter,
            action.payload.position
          ),
        ],
      };
      break;
    case "setAttempt":
      newState = {
        ...state,
        ...setAttempt(
          action.payload.attempt,
          action.payload.index,
          state.attempts || [],
          state.gameLetters || []
        ),
      };
      break;
    case "addGuess":
      newState = {
        ...state,
        guesses: (() => {
          const { template, guess, final } = action.payload;
          const templateGuesses = (state.guesses || {})[template];
          const newGuesses = final
            ? [...templateGuesses, guess]
            : [guess, ...templateGuesses];
          return {
            ...state.guesses,
            [template]: newGuesses,
          };
        })(),
      };
      break;
    case "updateGuess":
      newState = {
        ...state,
        guesses: (() => {
          const { template, index, guess } = action.payload;
          const { guesses = {} } = state;
          const newGuesses = [
            ...guesses[template].slice(0, index),
            guess.toUpperCase(),
            ...guesses[template].slice(index + 1),
          ];
          return {
            ...guesses,
            [template]: newGuesses,
          };
        })(),
      };
      break;
    case "removeGuess":
      newState = {
        ...state,
        guesses: (() => {
          const { template, index } = action.payload;
          const { guesses = {} } = state;
          const newGuesses = [
            ...guesses[template].slice(0, index),
            ...guesses[template].slice(index + 1),
          ];
          return {
            ...guesses,
            [template]: newGuesses,
          };
        })(),
      };
      break;
    case "removeIncorrect":
      newState = {
        ...state,
        guesses: removeIncorrectGuesses(state),
      };
      break;
    case "reset":
      newState =
        state.resetState === "started"
          ? defaultGameState
          : {
              ...state,
              resetState: "started",
            };
      break;
    default:
      break;
  }

  if (action.type !== "reset") {
    newState.resetState = undefined;
  }

  if (newState === state) return state as GameStateShape;

  const finalState = getHintedPositions(newState);
  if (!guessActions.includes(action.type as typeof guessActions[number])) {
    finalState.guesses = getGuesses(newState);
  }

  if (objectHasMatch(finalState, state)) return state as GameStateShape;

  return finalState as GameStateShape;
};

export const GameStateProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const parsedGameState = useMemo(() => {
    const origGameState = getStoredGameState();
    const finalGameState = gameStateReducer(origGameState, { type: "init" });
    return finalGameState;
  }, []);
  const [gameState, dispatch] = useReducer(gameStateReducer, parsedGameState);

  useEffect(() => {
    storeGameState(gameState);
  }, [gameState]);

  const setEliminatedLetters = useCallback(
    (letters: Maybe<AlphabetLetter>[]) => {
      dispatch({ type: "setEliminatedLetters", payload: letters });
    },
    []
  );

  const addEliminatedLetter = useCallback((letter: AlphabetLetter) => {
    dispatch({ type: "addEliminatedLetter", payload: letter });
  }, []);

  const removeEliminatedLetter = useCallback((letter: AlphabetLetter) => {
    dispatch({ type: "removeEliminatedLetter", payload: letter });
  }, []);

  const toggleEliminatedLetter = useCallback((letter: AlphabetLetter) => {
    dispatch({ type: "toggleEliminatedLetter", payload: letter });
  }, []);

  const updateDiscoveredLetterCount = useCallback(
    (payload: UpdateDiscoveredLetterPayload) => {
      dispatch({ type: "updateDiscoveredLetterCount", payload });
    },
    []
  );

  const updateDiscoveredLetterPosition = useCallback(
    (payload: UpdateDiscoveredLetterPositionPayload) => {
      dispatch({ type: "updateDiscoveredLetterPosition", payload });
    },
    []
  );

  const addCorrectLetter = useCallback((payload: AddCorrectLetterPayload) => {
    dispatch({ type: "addCorrectLetter", payload });
  }, []);

  const removeCorrectLetter = useCallback(
    (payload: AddCorrectLetterPayload) => {
      dispatch({ type: "removeCorrectLetter", payload });
    },
    []
  );

  const setAttempt = useCallback((attempt: Attempt, index: number) => {
    dispatch({ type: "setAttempt", payload: { attempt, index } });
  }, []);

  const addGuess = useCallback((payload: AddGuessPayload) => {
    dispatch({ type: "addGuess", payload });
  }, []);

  const updateGuess = useCallback((payload: UpdateGuessPayload) => {
    dispatch({ type: "updateGuess", payload });
  }, []);

  const removeGuess = useCallback((payload: RemoveGuessPayload) => {
    dispatch({ type: "removeGuess", payload });
  }, []);

  const removeIncorrect = useCallback(() => {
    dispatch({ type: "removeIncorrect", payload: undefined });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: "reset", payload: undefined });
  }, []);

  return (
    <GameStateContext.Provider
      value={{
        state: gameState,
        setEliminatedLetters,
        addEliminatedLetter,
        removeEliminatedLetter,
        toggleEliminatedLetter,
        updateDiscoveredLetterCount,
        updateDiscoveredLetterPosition,
        addCorrectLetter,
        removeCorrectLetter,
        setAttempt,
        addGuess,
        updateGuess,
        removeGuess,
        removeIncorrect,
        reset,
      }}
    >
      {children || <></>}
    </GameStateContext.Provider>
  );
};
