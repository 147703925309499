import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const darkQuery = "(prefers-color-scheme: dark)";
const getColorScheme = (queryList: MediaQueryList | MediaQueryListEvent) =>
  queryList.matches ? "dark" : "light";

export const useColorScheme = () => {
  const [matchQuery] = useState(window.matchMedia(darkQuery));
  const [colorScheme, setColorScheme] = useState(getColorScheme(matchQuery));

  useEffect(() => {
    const listener = (event: MediaQueryListEvent) => {
      setColorScheme(getColorScheme(event));
    };

    matchQuery.addEventListener("change", listener);
    return () => matchQuery.removeEventListener("change", listener);
  }, [matchQuery]);

  return colorScheme;
};

const tenYears = 60 * 60 * 24 * 400; // Chrome limits max-age to 400 days. https://tinyurl.com/dayslimit

export const useFirstTimeCookie = <T>(
  cookieName: string,
  defaultValue: T
): [T, (val: T) => void] => {
  const [{ [cookieName]: foundCookie = defaultValue }, setCookie] = useCookies<
    string,
    { [key: string]: T }
  >([cookieName]);

  const setFirstTimeCookie = useCallback(
    <T>(cookieValue: T) => {
      setCookie(cookieName, cookieValue, {
        path: "/",
        maxAge: tenYears,
      });
    },
    [setCookie, cookieName]
  );

  return [foundCookie, setFirstTimeCookie];
};
