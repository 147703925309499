import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { GameStateContext } from "./GameStateContext";
import {
  AlphabetLetter,
  GameLetter,
  alphabetLetters,
  getLetter,
  hasState,
} from "./gameStateDataStructures";

import {
  LetterButtonDisplay,
  OnlyLetterButtonElementTypes,
} from "./LetterButtonDisplay";

const EliminationContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;

  border: 1px solid rgb(255 255 255 / 0%);
`;

const EliminationRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  & > * {
    margin: 0.15rem;
  }
`;

const EliminatedButtonDisplay = styled(LetterButtonDisplay)`
  font-size: 0.85rem;
`;

function LetterButton({
  gameLetter,
  focused,
}: {
  gameLetter: GameLetter;
  focused?: Maybe<boolean>;
}) {
  const letter = gameLetter.letter;
  const [buttonRef, setButtonRef] =
    useState<Maybe<OnlyLetterButtonElementTypes>>(null);
  const { toggleEliminatedLetter } = useContext(GameStateContext);
  const isCorrect = hasState(gameLetter, "correct");
  const isDiscovered = !isCorrect && gameLetter.instanceCount > 0;
  const isEliminated = !(isCorrect || isDiscovered) && gameLetter.eliminated;
  const isHinted = hasState(gameLetter, "hinted");

  useEffect(() => {
    if (focused && buttonRef) {
      buttonRef.focus();
    }
  }, [focused, buttonRef]);

  return (
    <>
      <label className="sr-only" htmlFor={`elimination-letter-${letter}`}>
        {`${isEliminated ? "" : "Non-"}Eliminated Letter ${letter}`}
      </label>
      <EliminatedButtonDisplay
        isEliminated={isEliminated}
        isDiscovered={isDiscovered}
        isCorrect={isCorrect}
        isHinted={isHinted}
        onClick={() => toggleEliminatedLetter(letter)}
        ref={setButtonRef}
        id={`elimination-letter-${letter}`}
        disabled={isDiscovered || isCorrect}
      >
        {letter}
      </EliminatedButtonDisplay>
    </>
  );
}

export default function LetterEliminationSection() {
  const {
    state: { gameLetters = [] },
  } = useContext(GameStateContext);
  const [containerRef, setContainerRef] = useState<Maybe<HTMLElement>>(null);
  const [focusedLetter, setFocusedLetter] = useState<Maybe<AlphabetLetter>>();
  const orderedGameLetters = alphabetLetters
    .map((letter) => getLetter(letter, gameLetters))
    .filter((letter): letter is GameLetter => !!letter);
  const letterRow1 = orderedGameLetters.slice(0, 10);
  const letterRow2 = orderedGameLetters.slice(10, 19);
  const letterRow3 = orderedGameLetters.slice(19);
  const { toggleEliminatedLetter } = useContext(GameStateContext);

  useEffect(() => {
    if (!containerRef) {
      return;
    }
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key.length === 1) {
        const letter = event.key.toUpperCase() as AlphabetLetter;
        if (!alphabetLetters.includes(letter)) {
          return;
        }
        setFocusedLetter(letter);
        toggleEliminatedLetter(event.key.toUpperCase() as AlphabetLetter);
      }
    };
    containerRef.addEventListener("keydown", handleKeyDown);
    return () => {
      containerRef.removeEventListener("keydown", handleKeyDown);
    };
  }, [containerRef, toggleEliminatedLetter]);

  return (
    <EliminationContainer ref={setContainerRef}>
      <EliminationRow>
        {letterRow1.map((gameLetter, ind) => (
          <LetterButton
            key={gameLetter.letter}
            gameLetter={gameLetter}
            focused={gameLetter.letter === focusedLetter}
          />
        ))}
      </EliminationRow>
      <EliminationRow>
        {letterRow2.map((gameLetter) => (
          <LetterButton
            key={gameLetter.letter}
            gameLetter={gameLetter}
            focused={gameLetter.letter === focusedLetter}
          />
        ))}
      </EliminationRow>
      <EliminationRow>
        {letterRow3.map((gameLetter) => (
          <LetterButton
            key={gameLetter.letter}
            gameLetter={gameLetter}
            focused={gameLetter.letter === focusedLetter}
          />
        ))}
      </EliminationRow>
    </EliminationContainer>
  );
}
