/// <reference types="styled-components/cssprop" />

import React, { useCallback, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { LetterButtonDisplay, LetterButtonProps } from "./LetterButtonDisplay";
import { callRefs } from "./reactUtils";

const inputCaretAnimation = keyframes`
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

export const HiddenInput = styled.input`
  outline: none;
  overflow: hidden;
  display: block;
  position: absolute;
  width: 5px;
  height: 1px;
  z-index: -1;
  color: transparent;
  caret-color: transparent;
`;

const letterInputFocusStyles = css`
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0.15em;
    height: 20%;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);

    border: 0.1667em solid var(--input-text-color);
    border-top: none;

    animation: ${inputCaretAnimation} 1s infinite;
  }
`;

export const LetterInputDisplay = styled(LetterButtonDisplay)`
  position: relative;
  border-radius: 0.416667em;

  animation: none !important;

  &.focused {
    ${letterInputFocusStyles}
  }
`;

const HiddenLetterInput = styled(HiddenInput)`
  outline: none;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  caret-color: transparent;
  opacity: 0.1;

  &:focus {
    & + ${LetterInputDisplay} {
      ${letterInputFocusStyles}
    }
  }
`;

type StyledInputProps = StyledComponentProps<typeof HiddenLetterInput>;
type LetterInputDisplayProps = Omit<
  LetterButtonProps<boolean, boolean, boolean>,
  "onChange" | "onKeyDown"
>;
type UnstyledLetterInputProps = Omit<StyledInputProps, "as">;

export type LetterInputProps = LetterInputDisplayProps &
  UnstyledLetterInputProps & {
    letter: Maybe<string>;
  };

export const nativeInputValueSetter =
  Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")
    ?.set ||
  (() => {
    // noop
  });

export const LetterInput = React.forwardRef<HTMLInputElement, LetterInputProps>(
  function LetterInputFunc(
    {
      letter,
      css,
      style,
      className: classNameProp,
      isDiscovered,
      isCorrect,
      isEliminated,
      isHinted,
      title,
      ...inputProps
    },
    ref
  ) {
    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const className = `${classNameProp || ""} letter-input`.trim();
    const styleProps = {
      css,
      style,
      className,
      isDiscovered,
      isCorrect,
      isEliminated,
      isHinted,
      title,
    };

    const updateInput = useCallback<
      React.KeyboardEventHandler<HTMLInputElement>
    >(
      (e) => {
        if (e.ctrlKey || e.metaKey || e.altKey) return;

        const newValue = e.key;
        const newInputValue =
          newValue === "Backspace" || newValue === "Delete" ? "" : newValue;

        nativeInputValueSetter.call(e.target, newInputValue);
        e.target?.dispatchEvent(new Event("input", { bubbles: true }));
        (
          inputProps.onKeyDown ||
          (() => {
            // noop
          })
        )(e);
      },
      [inputProps.onKeyDown]
    );

    return (
      <>
        <HiddenLetterInput
          ref={(r: Maybe<HTMLInputElement>) => callRefs(r, ref, setInputRef)}
          type="textbox"
          {...inputProps}
          value={letter || ""}
          onKeyDown={updateInput}
        />
        <LetterInputDisplay
          forwardedAs="span"
          onClick={() => inputRef?.focus()}
          {...styleProps}
        >
          {letter}
        </LetterInputDisplay>
      </>
    );
  }
);
