import React, { useCallback, useEffect, useMemo } from "react";
import { useGameState } from "./GameStateContext";
import { AlphabetLetter } from "./gameStateDataStructures";
import { useColorScheme } from "./windowUtils";
import { extractCorrectLetters } from "./game-state/correctLetters";
import { getDiscoveredLetters } from "./game-state/discoveredLetters";

const greenFavicon =
  "%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 72 72' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23e3e1da;%7D.cls-2%7Bfill:%23519645;%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-2' x='2.5' y='2.5' width='67' height='67' rx='6.68' ry='6.68'/%3E%3Cpath class='cls-1' d='m62.82,5c2.31,0,4.18,1.88,4.18,4.18v53.64c0,2.31-1.88,4.18-4.18,4.18H9.18c-2.31,0-4.18-1.88-4.18-4.18V9.18c0-2.31,1.88-4.18,4.18-4.18h53.64m0-5H9.18C4.11,0,0,4.11,0,9.18v53.64c0,5.07,4.11,9.18,9.18,9.18h53.64c5.07,0,9.18-4.11,9.18-9.18V9.18c0-5.07-4.11-9.18-9.18-9.18h0Z'/%3E%3Cpath class='cls-1' d='m12.85 22.68h9.97l3.96 17.75 4.88-17.75h9.05l4.66 17.63 3.57-17.63h10.23l-8.66 28.64h-9.62l-4.7-17.54-5.09 17.54h-9.53l-8.71-28.64z'/%3E%3C/svg%3E%0A";
const yellowFavicon =
  "%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 72 72' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23f2cb05;%7D.cls-2%7Bfill:%238492cf;%7D.cls-3%7Bfill:%23090d1c;%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-1' x='2.5' y='2.5' width='67' height='67' rx='6.68' ry='6.68'/%3E%3Cpath class='cls-2' d='m62.82,5c2.31,0,4.18,1.88,4.18,4.18v53.64c0,2.31-1.88,4.18-4.18,4.18H9.18c-2.31,0-4.18-1.88-4.18-4.18V9.18c0-2.31,1.88-4.18,4.18-4.18h53.64m0-5H9.18C4.11,0,0,4.11,0,9.18v53.64C0,67.89,4.11,72,9.18,72h53.64c5.07,0,9.18-4.11,9.18-9.18V9.18c0-5.07-4.11-9.18-9.18-9.18h0Z'/%3E%3Cpath class='cls-3' d='m12.85 22.68h9.97l3.96 17.75 4.88-17.75h9.05l4.66 17.63 3.57-17.63h10.23l-8.66 28.64h-9.62l-4.7-17.54-5.09 17.54h-9.53l-8.71-28.64z'/%3E%3C/svg%3E%0A";
const blackFavicon =
  "%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 72 72' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23e3e1da;%7D.cls-2%7Bfill:%23090d1c;%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-2' x='2.5' y='2.5' width='67' height='67' rx='6.68' ry='6.68'/%3E%3Cpath class='cls-1' d='m62.82,5c2.31,0,4.18,1.88,4.18,4.18v53.64c0,2.31-1.88,4.18-4.18,4.18H9.18c-2.31,0-4.18-1.88-4.18-4.18V9.18c0-2.31,1.88-4.18,4.18-4.18h53.64M62.82,0H9.18C4.11,0,0,4.11,0,9.18v53.64c0,5.07,4.11,9.18,9.18,9.18h53.64c5.07,0,9.18-4.11,9.18-9.18V9.18C72,4.11,67.89,0,62.82,0h0Z'/%3E%3Cpath class='cls-1' d='m12.85 22.68h9.97l3.96 17.75 4.88-17.75h9.05l4.66 17.63 3.57-17.63h10.23l-8.66 28.64h-9.62l-4.7-17.54-5.09 17.54h-9.53l-8.71-28.64z'/%3E%3C/svg%3E%0A";
const whiteFavicon =
  "%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 72 72' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23e3e1da;%7D.cls-2%7Bfill:%23090d1c;%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-1' x='2.5' y='2.5' width='67' height='67' rx='6.68' ry='6.68'/%3E%3Cpath class='cls-2' d='m62.82,5c2.31,0,4.18,1.88,4.18,4.18v53.64c0,2.31-1.88,4.18-4.18,4.18H9.18c-2.31,0-4.18-1.88-4.18-4.18V9.18c0-2.31,1.88-4.18,4.18-4.18h53.64M62.82,0H9.18C4.11,0,0,4.11,0,9.18v53.64C0,67.89,4.11,72,9.18,72h53.64c5.07,0,9.18-4.11,9.18-9.18V9.18C72,4.11,67.89,0,62.82,0h0Z'/%3E%3Cpath class='cls-2' d='m12.85 22.68h9.97l3.96 17.75 4.88-17.75h9.05l4.66 17.63 3.57-17.63h10.23l-8.66 28.64h-9.62l-4.7-17.54-5.09 17.54h-9.53l-8.71-28.64z'/%3E%3C/svg%3E%0A";

export default function Favicon() {
  const {
    state: { gameLetters },
  } = useGameState();
  const discoveredLetters = useMemo(
    () => getDiscoveredLetters(gameLetters),
    [gameLetters]
  );
  const correctLetters = useMemo(
    () => extractCorrectLetters(gameLetters),
    [gameLetters]
  );
  const colorScheme = useColorScheme();

  const getFavicon = useCallback(() => {
    let favicon = colorScheme === "dark" ? blackFavicon : whiteFavicon;
    favicon =
      (discoveredLetters
        ?.map((dl) => dl?.letter)
        .filter((dl): dl is AlphabetLetter => Boolean(dl)).length || 0) > 0
        ? yellowFavicon
        : favicon;
    favicon = correctLetters?.validValues.length > 0 ? greenFavicon : favicon;
    return favicon;
  }, [discoveredLetters, correctLetters, colorScheme]);

  useEffect(() => {
    const svgHref = `data:image/svg+xml,${getFavicon()}`;
    const setIconContent = (link: Maybe<HTMLLinkElement>) => {
      if (!link) return;

      link.type = "image/svg+xml";
      link.rel = "shortcut icon";
      link.href = svgHref;
      document.head.removeChild(link);
      document.head.prepend(link.cloneNode(true));
    };

    const faviconlink = document.querySelector(
      "link[rel*='icon']"
    ) as HTMLLinkElement;
    const touchIconLink = document.querySelector(
      "link[rel*='apple-touch-icon']"
    ) as HTMLLinkElement;
    setIconContent(faviconlink);
    setIconContent(touchIconLink);
  }, [getFavicon]);

  return <></>;
}
