import { GameStateShape } from "../GameStateContext";
import { AlphabetLetter, GameLetter } from "../gameStateDataStructures";

export function setEliminatedLetters(
  gameState: Partial<GameStateShape>,
  eliminatedLetters: Maybe<AlphabetLetter>[] = []
): Partial<GameStateShape> {
  const existingEliminated =
    gameState.gameLetters
      ?.filter((gl) => gl.eliminated)
      .map<AlphabetLetter>((gl) => gl.letter) || [];
  const removeLetters = [...existingEliminated].filter(
    (l) => !eliminatedLetters.includes(l)
  );
  const addLetters = [...eliminatedLetters].filter<AlphabetLetter>(
    (l): l is AlphabetLetter => (l ? !existingEliminated.includes(l) : false)
  );

  let newGameState = addLetters.reduce(addEliminatedLetter, { ...gameState });
  newGameState = removeLetters.reduce(removeEliminatedLetter, newGameState);

  return newGameState;
}

function getUpdatedGameLetters(
  gameLetters: GameLetter[],
  newGameLetter: Pick<GameLetter, "letter" | "eliminated">
): GameLetter[] {
  return [...gameLetters].map((gl) => {
    if (gl.letter !== newGameLetter.letter) return gl;

    return {
      ...gl,
      ...newGameLetter,
    };
  });
}

export function addEliminatedLetter(
  gameState: Partial<GameStateShape>,
  letter: AlphabetLetter
): Partial<GameStateShape> {
  const gameLetters = getUpdatedGameLetters(
    gameState.gameLetters as GameLetter[],
    {
      letter,
      eliminated: true,
    }
  );

  return {
    ...gameState,
    gameLetters,
  };
}

export function removeEliminatedLetter(
  gameState: Partial<GameStateShape>,
  letter: AlphabetLetter
): Partial<GameStateShape> {
  const gameLetters = getUpdatedGameLetters(
    gameState.gameLetters as GameLetter[],
    {
      letter,
      eliminated: false,
    }
  );

  return {
    ...gameState,
    gameLetters,
  };
}

export function toggleEliminatedLetter(
  gameState: Partial<GameStateShape>,
  letter: AlphabetLetter
): Partial<GameStateShape> {
  return !gameState.gameLetters?.find((l) => l.letter === letter)?.eliminated
    ? addEliminatedLetter(gameState, letter)
    : removeEliminatedLetter(gameState, letter);
}
