import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { GameStateContext } from "./GameStateContext";
import {
  AlphabetLetter,
  GameLetter,
  isAlphabetLetter,
} from "./gameStateDataStructures";
import {
  focusStyles,
  LetterButtonDisplay,
  LetterButtonDisplayLooseProps,
} from "./LetterButtonDisplay";
import {
  LetterInput,
  LetterInputProps,
  nativeInputValueSetter,
} from "./LetterInput";
import { getUniqueId } from "./uniqueId";
import { getDiscoveredLetters } from "./game-state/discoveredLetters";

const DiscoveredLetterInput = styled(LetterInput)<LetterInputProps>`
  font-size: 1.5em;
`;

const DiscoveredLetterPosition = styled(LetterButtonDisplay)`
  font-size: 0.75em;
  margin-left: 1em;

  &:disabled {
    opacity: 0.5;
  }
`;

const DiscoveredLetterContainer = styled.section<LetterButtonDisplayLooseProps>`
  padding: 0.5em;
  margin: 0.25em;
  border: 1px solid rgb(255 255 255 / 0%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:focus-within {
    .letter-input {
      ${focusStyles}
    }
  }
`;

const DiscoveredLetterPositionDisplay: React.FC<{
  index: number;
  gameLetter?: GameLetter;
  updateInput: (val: string) => void;
}> = ({ index, gameLetter, updateInput }) => {
  const {
    state: { gameLetters },
  } = useContext(GameStateContext);
  const hintedLetter = useMemo(
    () =>
      gameLetters?.find((gl) =>
        ["hinted", "correct"].includes(gl?.positions[index]?.state)
      ),
    [gameLetters, index]
  );
  const positionDisplay = index + 1;
  const isExcluded = Boolean(hintedLetter);
  const isExactHint = gameLetter?.positions[index]?.state === "hinted";
  const isEliminated =
    !isExcluded && gameLetter?.positions[index]?.state === "incorrect";

  let status = isExcluded ? "Unavailable" : "Possible";
  status = isEliminated ? "Incorrect" : status;
  status = isExactHint ? "Probable" : status;

  const title = `${status} position: ${positionDisplay}`;

  return (
    <DiscoveredLetterPosition
      key={positionDisplay}
      isCorrect={isExactHint}
      isHinted={isExcluded}
      disabled={isExcluded}
      isEliminated={isEliminated}
      onKeyDown={(e) => {
        updateInput(e.key);
      }}
      onClick={() => {
        updateInput(positionDisplay.toString());
      }}
      title={title}
    >
      {hintedLetter?.letter || positionDisplay}
    </DiscoveredLetterPosition>
  );
};

type DiscoveredLetterProps = Omit<LetterInputProps, "letter"> & {
  gameLetter?: GameLetter;
};

const DiscoveredLetterUI = ({
  gameLetter,
  ...props
}: DiscoveredLetterProps) => {
  const { letter } = gameLetter || {};
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [descriptionId] = useState(
    `discovered-letter-${getUniqueId()}-description`
  );
  const isHinted = gameLetter?.positions.some((p) => p.state === "hinted");
  const wrongPositions =
    gameLetter?.positions
      .filter((p) => p.state === "incorrect")
      .map((p, i) => i + 1) || [];

  const updateInput = useCallback(
    (newValue: string) => {
      if (!inputRef) return;

      const newInputValue =
        newValue === "Backspace" || newValue === "Delete" ? "" : newValue;

      nativeInputValueSetter.call(inputRef, newInputValue);
      inputRef.dispatchEvent(new Event("input", { bubbles: true }));
    },
    [inputRef]
  );

  return (
    <DiscoveredLetterContainer
      isDiscovered={!isHinted}
      isHinted={isHinted}
      aria-describedby={descriptionId}
    >
      <p className="sr-only" id={descriptionId}>
        Discovered letter: {letter || "empty"};
        {[...wrongPositions].filter(Boolean).length
          ? `Incorrect positions: ${wrongPositions.join(", ")}`
          : "No incorrect positions"}
      </p>
      <DiscoveredLetterInput
        ref={setInputRef}
        letter={letter}
        isDiscovered={!isHinted}
        isCorrect={isHinted}
        onClick={() => inputRef?.focus()}
        aria-label={`Discovered letter: ${letter || "empty"}`}
        {...props}
      />
      {[...Array(5)].map((_, i) => (
        <DiscoveredLetterPositionDisplay
          key={i}
          index={i}
          gameLetter={gameLetter}
          updateInput={updateInput}
        />
      ))}
    </DiscoveredLetterContainer>
  );
};

export default function DiscoveredLettersSection() {
  const {
    state: { gameLetters },
    updateDiscoveredLetterCount,
    updateDiscoveredLetterPosition,
  } = useContext(GameStateContext);
  const discoveredLetters = useMemo(() => {
    const dLs = getDiscoveredLetters(gameLetters) || [];
    return dLs.reduce((acc, dl) => {
      const correctCount = dl.positions.filter(
        (p) => p.state === "correct"
      ).length;
      const instanceTotal = dl.instanceCount - correctCount;
      const guessOrders = dl.guessOrders.slice(0, instanceTotal);

      while (guessOrders.length !== instanceTotal) {
        guessOrders.push((guessOrders[guessOrders.length - 1] ?? -1) + 1);
      }

      guessOrders.forEach((go) => {
        acc[go] = dl;
      });
      return acc;
    }, [] as (GameLetter | undefined)[]);
  }, [gameLetters]);

  const getLetterCount = useCallback(
    (letter: AlphabetLetter) => {
      return (
        gameLetters?.find((dl) => dl.letter === letter)?.instanceCount || 0
      );
    },
    [gameLetters]
  );

  const removeLetterInstance = useCallback(
    (letter: AlphabetLetter, position: number) => {
      const letterCount = Math.max(getLetterCount(letter) - 1, 0);

      updateDiscoveredLetterCount({
        letter,
        letterCount,
      });
    },
    [updateDiscoveredLetterCount, getLetterCount]
  );

  const addLetterInstance = useCallback(
    (letter: AlphabetLetter, guessOrder: number) => {
      const letterCount = getLetterCount(letter) + 1;
      updateDiscoveredLetterCount({
        letter,
        letterCount,
        guessOrder,
      });
    },
    [updateDiscoveredLetterCount, getLetterCount]
  );

  const updateLetterPosition = useCallback(
    (letter: AlphabetLetter, letterPosition: number) => {
      const targetLetter = gameLetters?.find((gl) => gl.letter === letter);
      const isIncorrect =
        targetLetter?.positions[letterPosition].state === "incorrect";

      updateDiscoveredLetterPosition({
        letter,
        position: letterPosition,
        incorrect: !isIncorrect,
      });
    },
    [updateDiscoveredLetterPosition, gameLetters]
  );

  const letterChanged = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      i: number,
      gameLetter: GameLetter
    ) => {
      if (e.target.value.length > 1) return;

      const newValue =
        (e.target as HTMLInputElement).value.split("").pop()?.toUpperCase() ||
        "";
      const oldLetter = gameLetter?.letter;

      if (isAlphabetLetter(newValue)) {
        if (newValue !== oldLetter) removeLetterInstance(oldLetter, i);

        addLetterInstance(newValue, i);
      }

      if (!newValue) {
        removeLetterInstance(oldLetter, i);
        return;
      }

      const newPositionValue = parseInt(newValue, 10) - 1;
      if (newPositionValue >= 0 && newPositionValue < 5) {
        updateLetterPosition(oldLetter, newPositionValue);
        return;
      }
    },
    [addLetterInstance, removeLetterInstance, updateLetterPosition]
  );

  const discoveredInstances = [
    ...(discoveredLetters || []),
    ...Array(5).fill(undefined),
  ].slice(0, 5);

  return (
    <>
      {discoveredInstances.map((letterInstance, i) => (
        <DiscoveredLetterUI
          key={i}
          gameLetter={letterInstance}
          onChange={(e) => letterChanged(e, i, letterInstance)}
        />
      )) || <></>}
    </>
  );
}
