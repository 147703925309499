import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { useGameState } from "./GameStateContext";
import {
  GameLetter,
  alphabetLetters,
  getLetter,
  hasState,
} from "./gameStateDataStructures";
import { LetterButtonDisplay } from "./LetterButtonDisplay";

const topAdjust = 10;
const topPosition = `${topAdjust}px`;

const GameBoardOuterContainer = styled.div`
  position: sticky;
  top: -1px;
  right: 0;
  margin-top: ${topPosition};
`;

const GameBoardBadgeDisplay = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1.3em;
  margin-top: 0.75em;
`;

const GameBoardContainer = styled.div<{
  positionType: "sticky" | "fixed";
  topPos?: number;
  rightPos: number;
}>`
  position: ${({ positionType }) => positionType};
  width: 100%;
  height: 0px;
  right: 0;
  margin-top: 0;
  z-index: 1000;
  overflow: visible;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  transition: top ease-in-out 100ms;

  ${({ positionType, topPos = 0 }) =>
    positionType === "sticky" &&
    css`
      padding-right: 4px;
    `}

  ${({ positionType, rightPos, topPos = 0 }) =>
    positionType === "fixed" &&
    css`
      position: fixed;
      top: ${topPos}px;
      right: ${rightPos}px;
    `}

  @media (min-width: ${({ theme }) => theme.desktopSize}) {
    padding-right: auto;
  }
`;

const GameBoardDisplay = styled.div`
  background-color: var(--opposite-background-color);
  padding: 28px 10px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
  position: relative;
  right: -2px;
`;

const GameBoardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const GameBoardTinyLetterDisplay = styled.div<{
  isEliminated?: boolean;
  isDiscovered?: boolean;
  isCorrect?: boolean;
}>`
  box-sizing: border-box;
  margin: 1px;
  width: 4px;
  height: 4px;
  background-color: var(--background-color);
  border: none;
  border-radius: 50%;

  ${({ isEliminated }) =>
    isEliminated &&
    css`
      background-color: #777;
    `}

  ${({ isDiscovered }) =>
    isDiscovered &&
    css`
      background-color: var(--discovered-color);
    `}

  ${({ isCorrect }) =>
    isCorrect &&
    css`
      background-color: var(--correct-color);
    `}
`;

const GameBoardTinyLetter: React.FC<{ gameLetter: GameLetter }> = ({
  gameLetter,
}) => {
  const isCorrect =
    hasState(gameLetter, "correct") || hasState(gameLetter, "hinted");
  const isDiscovered = !isCorrect && gameLetter.instanceCount > 0;
  const isEliminated = !(isCorrect || isDiscovered) && gameLetter.eliminated;
  return (
    <GameBoardTinyLetterDisplay
      {...{ isCorrect, isDiscovered, isEliminated }}
    />
  );
};

const GameBoardLabel = styled.div`
  background-color: var(--opposite-background-color);
  color: var(--opposite-text-color);
  position: relative;
  top: -16px;
  border-radius: 0.25em;
  font-weight: 700;
  padding: 0.15em 0.2em;
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const GameBoardLargeContainer = styled.div<{
  isOpen?: boolean;
  openHeight?: number;
}>`
  position: absolute;
  width: calc(100% - 2em);
  margin: 0.7em 1em;
  overflow: hidden;
  height: ${({ isOpen, openHeight = 120 }) => (isOpen ? openHeight : 0)}px;
  background-color: transparent;
  border-radius: 1.1em;
  transition: height ease-in-out 250ms;
`;

const GameBoardLargeInner = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1em 6.5em 1em 0.75em;
  background-color: var(--opposite-background-color);
  border-radius: 1.1em;
`;

const GameBoardButtonDisplay = styled(LetterButtonDisplay)`
  font-size: 0.75rem;
`;

const GameBoardButton: React.FC<{ gameLetter: GameLetter }> = ({
  gameLetter,
}) => {
  const isCorrect =
    hasState(gameLetter, "correct") || hasState(gameLetter, "hinted");
  const isDiscovered = !isCorrect && gameLetter.instanceCount > 0;
  const isEliminated = !(isCorrect || isDiscovered) && gameLetter.eliminated;

  return (
    <GameBoardButtonDisplay
      {...{ isCorrect, isDiscovered, isEliminated }}
      inverted
      disabled
    >
      {gameLetter.letter}
    </GameBoardButtonDisplay>
  );
};

export default function GameBaord() {
  const [boardOpen, setBoardOpen] = useState(false);
  const [gameBoardHeight, setGameBoardHeight] = useState(120);
  const [positionType, setPositionType] = useState<"fixed" | "sticky">(
    "sticky",
  );
  const [topPos, setTopPos] = useState(0);
  const [rightPos, setRightPos] = useState(0);
  const [mainElement, setMainElement] = useState<HTMLDivElement | null>(null);
  const currentIntersection = useRef<() => void>();

  const toggleBoardOpen = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >((ev) => {
    setBoardOpen((prev) => !prev);
  }, []);

  const {
    state: { gameLetters },
  } = useGameState();

  const [letterRow1, letterRow2, letterRow3] = useMemo(() => {
    const orderedGameLetters = alphabetLetters
      .map((letter) => getLetter(letter, gameLetters || []))
      .filter((letter): letter is GameLetter => !!letter);
    const row1 = orderedGameLetters.slice(0, 10);
    const row2 = orderedGameLetters.slice(10, 19);
    const row3 = orderedGameLetters.slice(19);
    return [row1, row2, row3];
  }, [gameLetters]);

  const gameBoardHeightRef = useCallback<React.RefCallback<HTMLDivElement>>(
    (gameBoardRef) => {
      setGameBoardHeight(gameBoardRef?.clientHeight || 120);
    },
    [],
  );

  const positionTypeRef = useCallback<React.RefCallback<HTMLDivElement>>(
    (mainEl) => {
      if (!mainEl) return;

      const observer = new IntersectionObserver(
        ([e]) => {
          currentIntersection.current = () => {
            setRightPos(mainEl.getBoundingClientRect().left);
            setPositionType(e.isIntersecting ? "sticky" : "fixed");
          };
          currentIntersection.current();
        },
        { threshold: [0] },
      );

      observer.observe(mainEl);
      setMainElement(mainEl);
    },
    [],
  );

  useEffect(() => {
    const setPosTypeFromViewport = (viewportOffset: number) => {
      const mainElRect = mainElement?.getBoundingClientRect();
      const mainElPos = (mainElRect?.top || 0) + gameBoardHeight * 1.25;

      if (viewportOffset > mainElPos) {
        setPositionType("fixed");
      } else {
        currentIntersection.current?.();
      }
    };

    const setTopPosFromViewport = (viewportOffset: number) => {
      const newTopPos = viewportOffset + topAdjust;
      setTopPos(newTopPos);
    };

    const viewportTracker = () => {
      const viewportOffset = window.visualViewport?.offsetTop || 0;
      setTopPosFromViewport(viewportOffset);
      setTimeout(() => setPosTypeFromViewport(viewportOffset), 0);
    };

    window.visualViewport?.addEventListener("scroll", viewportTracker, true);
    window.visualViewport?.addEventListener("resize", viewportTracker, true);

    return () => {
      window.visualViewport?.removeEventListener(
        "scroll",
        viewportTracker,
        true,
      );
      window.visualViewport?.removeEventListener(
        "resize",
        viewportTracker,
        true,
      );
    };
  }, [gameBoardHeight, mainElement]);

  return (
    <>
      <GameBoardOuterContainer ref={positionTypeRef} />
      <GameBoardContainer
        {...{
          positionType,
          rightPos,
          topPos,
        }}
      >
        <GameBoardLargeContainer
          isOpen={boardOpen}
          openHeight={gameBoardHeight}
        >
          <GameBoardLargeInner ref={gameBoardHeightRef}>
            <GameBoardRow>
              {letterRow1.map((gameLetter) => (
                <GameBoardButton
                  key={gameLetter.letter}
                  gameLetter={gameLetter}
                />
              ))}
            </GameBoardRow>
            <GameBoardRow>
              {letterRow2.map((gameLetter) => (
                <GameBoardButton
                  key={gameLetter.letter}
                  gameLetter={gameLetter}
                />
              ))}
            </GameBoardRow>
            <GameBoardRow>
              {letterRow3.map((gameLetter) => (
                <GameBoardButton
                  key={gameLetter.letter}
                  gameLetter={gameLetter}
                />
              ))}
            </GameBoardRow>
          </GameBoardLargeInner>
        </GameBoardLargeContainer>
        <GameBoardBadgeDisplay
          onClick={toggleBoardOpen}
          data-prevent-focus-loss
        >
          <GameBoardDisplay>
            <GameBoardRow>
              {letterRow1.map((gameLetter) => (
                <GameBoardTinyLetter
                  key={gameLetter.letter}
                  gameLetter={gameLetter}
                />
              ))}
            </GameBoardRow>
            <GameBoardRow>
              {letterRow2.map((gameLetter) => (
                <GameBoardTinyLetter
                  key={gameLetter.letter}
                  gameLetter={gameLetter}
                />
              ))}
            </GameBoardRow>
            <GameBoardRow>
              {letterRow3.map((gameLetter) => (
                <GameBoardTinyLetter
                  key={gameLetter.letter}
                  gameLetter={gameLetter}
                />
              ))}
            </GameBoardRow>
          </GameBoardDisplay>
          <GameBoardLabel>
            <span>Game Board</span>
          </GameBoardLabel>
        </GameBoardBadgeDisplay>
      </GameBoardContainer>
    </>
  );
}
